import React, {Component} from 'react'
import {getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {Field, FieldsManager} from '../../../data/services/fields'
import Resources from '../../../data/services/resources'
import {UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, classNames, getProp} from '../../../common/util/util-helpers'
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import Page from "../../../common/components/layout/layout-components/page";
import Card from "../../../common/components/card";
import {LoaderSmall} from "../../../common/components/loader";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import DriverPayrollSettings from "../driver-payroll/dialogs/driver-payroll-settings";

class PayrollSettingsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            canSubmit: false,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.resource.isLoading && (!this.props.resource.isLoading && !!this.props.resource.data)) {
            const item = getProp(this.props, 'resource.data', {})
            this.setState({
                fields: this.getFields(item),
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.PayrollSettingsGeneral
        }))
    }

    onSubmit = () => {
        if (!this.state.canSubmit) {
            return
        }

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.setState({canSubmit: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields)),
                        resource: Resources.PayrollSettingsGeneral,
                        piggyResource: Resources.PayrollSettingsGeneral,
                        errorMessage: true, successMessage: 'Settings saved successfully!'
                    }))
                })
            }
        })
    }

    /** UI Events
     ================================================================= */

    handleInputChange = (name, value) => {
        const newFields = FieldsManager.updateField(this.state.fields, name, value)

        this.setState({
            canSubmit: true,
            fields: newFields
        })
    }

    onCancel = () => {
        if (!this.state.canSubmit) {
            return
        }

        this.setState({
            canSubmit: false,
            fields: this.getFields()
        })
    }

    /** Helpers
     ================================================================= */
    getFields = (item = {}) => {
        const fieldTemplates = {
            ShowDispatchPay: new Field('ShowDispatchPay', '', [''], false, 'checkbox', {addContainerClass: 'col-span-full'}),
            DispatchPayDateDefault: new Field('DispatchPayDateDefault', '', ['empty'], false, 'select', {
                addContainerClass: 'col-span-full'
            }, {
                values: {
                    '1': 'Pickup Date',
                    '2': 'Delivery Date'
                }
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props
        const isLoading = this.props.resource.isLoading


        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange)

        const info = fieldsHtml.slice(0, 3)

        return (
            <Page>
                <div className="relative">
                    <div className={classNames('mt-6 grid gap-4 grid-cols-6', isLoading ? 'invisible' : '')}>
                        <Card addClass="col-span-6">
                            <h5 className="text-tm-gray-700 text-base">{translate('page.title.payroll')}</h5>

                            <div className="grid grid-cols-3">
                                {info}
                            </div>
                        </Card>

                        <Card addClass="col-span-6">
                            <h5 className="mb-4 text-tm-gray-700 text-base">{translate('text.accounts')}</h5>

                            <DriverPayrollSettings
                                location={this.props.location}
                                translate={translate}
                                dispatch={this.props.dispatch}
                                thirdResource={this.props.thirdResource}
                            />
                        </Card>

                    </div>
                    {!!isLoading && (
                        <div className={'inset-0'}>
                            <div className="inset-center">
                                <LoaderSmall/>
                            </div>
                        </div>
                    )}

                    {!isLoading && (
                        <PageFooter
                            translate={translate}
                            canSubmit={this.state.canSubmit && checkPerm(Resources.AccountingSettings, UPDATE_PERM)}
                            actionCancel={this.onCancel}
                            actionSubmit={this.onSubmit}
                        />
                    )}
                </div>
            </Page>
        )
    }
}

export default PayrollSettingsTab
