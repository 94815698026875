import React, {Component} from 'react'
import {FieldsManager} from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import {
    ACCOUNT_TYPE_EXPENSE,
    ACCOUNT_TYPE_FIXED_ASSETS,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY, MAJOR_ACCOUNT_TYPE_EXPENSE,
} from '../../../../util/util-constants'
import {numberWithCommas, numberWithCommasToBack} from '../../../../util/util-formaters'
import {pushNotification} from '../../../../data/actions/ui'
import moment from 'moment-timezone'
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import {TrashIcon} from "@heroicons/react/24/outline";
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import ResourceTable from "../../../../common/components/resource-table";
import {getDefaultTableOptions} from "../../../../common/util/util-helpers";

export default class InvoiceExpenseItemTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            createModalOpen: false,
            editModalOpen: false,

            selectedItem: {},
            itemModalKey: 0,
            editItemModalKey: 10000,
            query: ''
        }

        this.itemsTableOptions = getDefaultTableOptions(this.getFields(), {
            style: {
                horizontalLines: true,
                verticalLines: true,
                condensed: true,
                isGPUAccelerated: true,
                condensedHeight: 38
            },
        }, 'invoice-expense-item-table', this.props.translate)
    }

    /** UI Events
     ================================================================= */
    deleteResource = (item) => {
        this.props.onRemoveItem(item.index)
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleEditModal = (item = {}) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value)
        const isIncome = this.props.isIncome

        let updateTotal = false

        if (name === 'AccountID') {
            fields.Advanced.value = value?.metadata?.IsChargeToContact ? true : false;
            fields.ChargeTo.type = value?.metadata?.IsChargeToContact ? 'button-group' : 'hidden';
            fields.ChargeTo.value = value?.metadata?.IsChargeToContact ? 1 : 2;
            fields.ChargeToContactID.type = value?.metadata?.IsChargeToContact && fields.ChargeTo.value === 1 ? 'select-search' : 'hidden';
            fields.ChargeToContactID.validate = value?.metadata?.IsChargeToContact && fields.ChargeTo.value === 1 ? ['empty'] : [''];
            fields.ChargeToOrganizationID.type = value?.metadata?.IsChargeToContact && fields.ChargeTo.value === 2 ? 'select-search' : 'hidden';
            fields.ChargeToOrganizationID.value = value?.metadata?.IsChargeToContact && null;
            fields.ChargeTo.disabled = value?.metadata?.IsChargeToContact ? true : false;
            fields.ChargeToContactID.disabled = false;
            fields.ChargeToOrganizationID.disabled = false;
        }

        if (name === 'ProductServiceID') {
            fields = FieldsManager.updateField(fields, 'AccountID',
                value
                    ? {
                        label: value.metadata?.ExpenseAccount,
                        value: value.metadata?.ExpenseAccountID
                    }
                    : ""
            )
            fields = FieldsManager.updateField(fields, 'Description',
                (isIncome ? value?.metadata?.IncomeDescription : value?.metadata?.ExpenseDescription) ?? ""
            )
            fields = FieldsManager.updateField(fields, 'Amount',
                isIncome ? (value?.metadata?.IncomePrice ?? 0) : (value?.metadata?.ExpensePrice ?? 0)
            )
            updateTotal = true
            if (fields?.ProductServiceTypeID?.value) {
                fields.ProductServiceTypeID.value = value?.metadata?.ProductServiceTypeID ?? ""
            }
        }

        if (name === 'Qty' || name === 'Amount' || name === 'TaxID' || updateTotal) {
            let total = (
                numberWithCommasToBack(fields.Qty.value)
                * numberWithCommasToBack(fields.Amount.value)
                * numberWithCommasToBack(
                    fields.TaxID.value?.amount // From select dropdown value
                    ?? 1) // Multiply with 1 if there is no TAX
            );

            let totalCalculation = numberWithCommas(Math.round(total * 100) / 100) ? numberWithCommas(Math.round(total * 100) / 100) : 0

            fields = FieldsManager.updateField(fields, 'TotalAmount', totalCalculation)
        }

        if (name === 'Qty' && (value === 0 || value === '0')) {
            fields = FieldsManager.updateField(fields, 'Qty', 1)
        }

        if (name === 'Total') {
            fields = FieldsManager.updateField(fields, 'Amount', value)
        }

        if (name === "PrepaidExpense") {
            fields['PrepaidExpenseAccountID'].value = ''
            fields['PrepaidExpenseCount'].value = 1

            fields['PrepaidExpenseAccountID'].type = value ? 'select-search' : 'hidden'
            fields['PrepaidExpenseCount'].type = value ? 'integer' : 'hidden'
        }

        if (name === 'ChargeTo') {
            fields['ChargeToContactID'].value = '';
            fields['ChargeToOrganizationID'].value = '';
            fields['ChargeToTruckID'].value = '';

            fields['ChargeToContactID'].type = value === 1 ? 'select-search' : 'hidden';
            fields['ChargeToOrganizationID'].type = value === 2 ? 'select-search' : 'hidden';
            fields['ChargeToTruckID'].type = value === 3 ? 'select-search' : 'hidden';

            fields['ChargeToContactID'].validate = (value === 1 && fields.AccountID?.value?.metadata?.IsChargeToContact) ? ['empty'] : [''];

            fields['ChargeToContactID'].disabled = value !== 2 && value !== 3;
            fields['ChargeToOrganizationID'].disabled = value !== 2;
            fields['ChargeToTruckID'].disabled = value !== 3;
        }

        if ('Advanced' === name) {
            fields.ChargeTo.type = value ? 'button-group' : 'hidden'
            fields.ChargeToContactID.type = value && fields.ChargeTo.value === 1 ? 'select-search' : 'hidden'

            fields.ChargeToOrganizationID.type = value && fields.ChargeTo.value === 2 ? 'select-search' : 'hidden'
            fields.ChargeToTruckID.type = value && fields.ChargeTo.value === 3 ? 'select-search' : 'hidden'
        }

        if ('Assets' === name) {
            fields.SerialNumber.type = value ? 'text' : 'hidden'

            fields.AssetNumber.type = value ? 'text' : 'hidden'

            fields.SalvageAmount.type = value ? 'float' : 'hidden'
            fields.SalvageAmount.validate = value ? ['empty'] : ['']

            fields.StartDate.type = value ? 'date' : 'hidden'
            fields.StartDate.validate = value ? ['empty'] : ['']

            fields.EndDate.type = value ? 'date' : 'hidden'
            fields.EndDate.validate = value ? ['empty'] : ['']

            fields.AccumulatedAccountID.type = value ? 'select-search' : 'hidden'
            fields.AccumulatedAccountID.validate = value ? ['empty'] : ['']

            fields.DepExpenseAccountID.type = value ? 'select-search' : 'hidden'
            fields.DepExpenseAccountID.validate = value ? ['empty'] : ['']

            fields.FixedAssetTypeID.type = value ? 'select' : 'hidden'
            fields.FixedAssetTypeID.validate = value ? ['empty'] : ['']

            fields.ProductServiceTypeID.value = value ? 4 : value.metadata?.ProductServiceTypeID

            fields.AccountID.props.key = 'AccountIDKey' + value // change key so component gets updated with new query
            fields.AccountID.value = ''

            fields.ProductServiceID.disabled = value
            fields.ProductServiceID.validate = value ? [] : ['empty']

            fields.Qty.disabled = !!value
            fields.Qty.value = value ? 1 : fields.Qty.value
        }

        if (name === 'FixedAssetTypeID') {
            const isAssetTruckOrTrailer = (Number(value) === 1 || Number(value) === 2)

            fields.Year.type = isAssetTruckOrTrailer ? 'integer' : 'hidden'
            fields.Manufacturer.type = isAssetTruckOrTrailer ? 'text' : 'hidden'
            fields.Model.type = isAssetTruckOrTrailer ? 'text' : 'hidden'
            fields.VIN.type = isAssetTruckOrTrailer ? 'text' : 'hidden'

            fields.Year.value = isAssetTruckOrTrailer ? fields.Year.value : ''
            fields.Manufacturer.value = isAssetTruckOrTrailer ? fields.Manufacturer.value : ''
            fields.Model.value = isAssetTruckOrTrailer ? fields.Model.value : ''
            fields.VIN.value = isAssetTruckOrTrailer ? fields.VIN.value : ''
        }

        if ('StartDate' === name) {
            fields.EndDate.metadata.minDate = () => moment(value, DEFAULT_DATABASE_DATETIME_FORMAT).add('month', 1).format(DEFAULT_DATABASE_DATETIME_FORMAT)
        }

        if ('EndDate' === name) {
            fields.StartDate.metadata.maxDate = () => moment(value, DEFAULT_DATABASE_DATETIME_FORMAT).subtract('month', 1).format(DEFAULT_DATABASE_DATETIME_FORMAT)
        }

        fields[name].errorMessage = ''

        return fields
    }

    /** Fields/Data definitions
     ================================================================= */
    getFields = (item = {}) => {
        return this.props.getFields(item)
    }

    getFormattedFields = () => {
        return this.props.items.map((it, index) => {
            return Object.assign({}, FieldsManager.getFieldKeyValues(it),
                {
                    index: index,
                    ProductService: it.ProductServiceID?.value?.label ?? '',
                    Account: it.AccountID?.value?.label ?? '',
                    ChargeToContact: it.ChargeToContactID?.value?.label ?? '',
                    ChargeToOrganization: it.ChargeToOrganizationID?.value?.label ?? '',
                    ChargeToTruck: it.ChargeToTruckID?.value?.label ?? '',
                    Tax: it.TaxID?.value?.label ?? '',
                    TaxAmount: it.TaxID?.value?.amount,
                    AccumulatedAccount: it.AccumulatedAccountID?.value?.label ?? '',
                    DepExpenseAccount: it.DepExpenseAccountID?.value?.label ?? '',
                    FixedAssetType: it.FixedAssetTypeID?.value?.label ?? ''
                })
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, hideAddInlineItemButton = false, renderCells} = this.props

        const metadata = {
            ProductServiceID: {
                api: 'api/' + Resources.ProductsServices,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ProductService,
                    value: item.ProductServiceID,
                    metadata: item
                })
            },
            TaxID: {
                api: 'api/' + Resources.AccountingTaxes,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.Tax,
                    value: item.TaxID,
                    amount: item.Amount
                })
            },
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName,
                    metadata: item
                })
            },
            ChargeToOrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.OrganizationID,
                    label: item.LegalName,
                    Organization: item
                })
            },
            ChargeToTruckID: {
                api: 'api/' + Resources.TrucksQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.TruckID,
                    label: item.Truck
                })
            },
            ChargeToContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + ' ' + item.LastName,
                    Contact: item
                })
            },
            AccumulatedAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    limit: 30,
                    offset: 0,
                    AccountTypeID: ACCOUNT_TYPE_FIXED_ASSETS
                },
                searchMap: (item) => ({
                    label: item.AccountName,
                    value: item.AccountID
                })
            },
            DepExpenseAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    limit: 20,
                    offset: 0,
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE
                },
                searchMap: (item) => ({
                    label: item.AccountName,
                    value: item.AccountID
                })
            },
            PrepaidExpenseAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    limit: 30,
                    offset: 0,
                    AccountTypeID: ACCOUNT_TYPE_EXPENSE
                },
                searchMap: (item) => ({
                    label: item.AccountName,
                    value: item.AccountID
                })
            },
        }

        const data = this.getFormattedFields()

        const filteredData = data.filter(it => {
            const Amount = it?.Amount + ''
            const TotalAmount = it?.TotalAmount + ''
            let canPass = true
            if (this.state.query) {
                canPass &=
                    it.Description?.toLowerCase().includes(this.state.query?.toLowerCase())
                    ||
                    it.Account?.toLowerCase().includes(this.state.query?.toLowerCase())
                    ||
                    (parseFloat(Amount)?.toFixed(2).toString().toLowerCase().includes(this.state.query?.toLowerCase()))
                    ||
                    (parseFloat(TotalAmount)?.toFixed(2).toString().toLowerCase().includes(this.state.query?.toLowerCase()))
            }

            return canPass
        })

        return (
            <div className={this.props.className}>
                {!!data.length && (
                    <React.Fragment>
                        <div className="flex items-center justify-between px-6">
                            <p className="text-lg leading-6 font-medium text-tm-gray-900">Items</p>

                            <div className="relative mr-auto mx-4">
                                <div className="flex items-center">
                                    <MagnifyingGlassIcon className="absolute left-4 w-5 h-5 text-tm-gray-600"/>

                                    <input
                                        type="text"
                                        placeholder={translate("text.search")}
                                        className="form-control pl-10 rounded-t-card"
                                        value={this.state.query}
                                        onChange={e => {
                                            this.setState({query: e.target.value})
                                        }}
                                    />
                                </div>

                            </div>

                            <button
                                onKeyDown={(event) => this.props.handleSelectFirstElement(event)}
                                onClick={this.handleToggleCreateModal}
                                className="btn btn-primary btn-xs">
                                {translate('btn.add_item')}
                            </button>
                        </div>

                        <div className="mt-6">
                            <ResourceTable
                                tableKey={"ExpenseLineID"}
                                maxHeightClass="max-h-[calc(100vh-30rem)]"
                                data={filteredData}
                                fields={this.getFields()}
                                options={this.itemsTableOptions}
                                renderCells={renderCells}
                                actions={[
                                    {
                                        action: this.deleteResource,
                                        icon: TrashIcon,
                                        visible: (item) => this.props.items.length > 1 && !item.IsPayed,
                                        title: translate('btn.delete'),
                                        order: 20
                                    },
                                    {
                                        action: this.handleToggleEditModal,
                                        icon: PencilIcon,
                                        visible: (item) => !item.IsPayed,
                                        title: translate('btn.edit'),
                                        order: 10
                                    }
                                ]}
                                translate={translate}
                            />
                        </div>

                        {!hideAddInlineItemButton && (
                            <div className="flex items-end justify-end">
                                <button ref={this.props.addBtnRef} className={"btn btn-outline   mx-2 my-5"}
                                        onClick={() => {
                                            this.props.onAddNewItem()
                                        }}>
                                    <span>Add and edit inline</span>
                                </button>
                            </div>
                        )}

                    </React.Fragment>
                )}

                {!data.length && (
                    <div className="py-4">
                        <NoRecords
                            canCreate={true}
                            show={true}
                            title={translate('text.no_items')}
                            text={translate('text.disabled_until_item_add')}
                            btnLabel={'Add new item'}
                            onBtnClick={this.handleToggleCreateModal}
                        />
                    </div>
                )}


                {/* Create new item dialog */}
                <ModalSaveResource
                    key={this.state.itemModalKey}
                    title={translate('dialog_heading.create_new_item')}
                    widthClass="max-w-md"
                    limitHeight={true}
                    gridColsClass="grid-cols-2"
                    show={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            if (params?.Assets?.value) { // ProductServiceTypeID is 4 when assets are checked
                                params.ProductServiceTypeID.value = 4
                            }

                            this.props.onAddNewItem(params)
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                    handleInputChange={this.handleInputChange}
                    getRawFields
                    secondaryButtonLabel={translate('btn.save_and_add_more')}
                    onSecondaryButtonClick={(params) => {
                        if (params) {
                            this.props.onAddNewItem(params)

                            this.setState({
                                itemModalKey: this.state.itemModalKey + 1
                            }, () => {
                                this.props.dispatch(pushNotification({
                                    title: this.props.translate('text.item_added'),
                                    timeout: true
                                }))
                            })
                        }
                    }}
                    disableSecondaryIfNotDirty={true}
                />

                <ModalSaveResource
                    key={this.state.editItemModalKey}
                    title={'Edit item'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-2"
                    limitHeight={true}
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            if (params?.Assets?.value) { // ProductServiceTypeID is 4 when assets are checked
                                params.ProductServiceTypeID.value = 4
                            }

                            if (this.state.selectedItem?.index > -1) {
                                this.props.onEditItem(params, this.state.selectedItem?.index)
                            } else {
                                this.props.onAddNewItem(params)
                            }
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                    handleInputChange={this.handleInputChange}
                    getRawFields
                    secondaryButtonLabel={translate('btn.save_and_add_more')}
                    onSecondaryButtonClick={(params) => {
                        const selectedItemIndex = this.state.selectedItem?.index
                        if (params) {
                            if (selectedItemIndex > -1) {
                                this.props.onEditItem(params, selectedItemIndex)
                            } else {
                                this.props.onAddNewItem(params)
                            }

                            this.setState({
                                editItemModalKey: this.state.editItemModalKey + 1,
                                selectedItem: undefined
                            }, () => {
                                this.props.dispatch(pushNotification({
                                    title: this.props.translate('text.item_edited'),
                                    timeout: true
                                }))
                            })
                        }
                    }}
                    disableSecondaryIfNotDirty={true}
                />
            </div>
        )
    }
}
