import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_QUERY_LIMIT, DELETE_PERM,
    DRIVER_STATUS_AVAILABLE,
    DRIVER_STATUS_BOOKED,
    DRIVER_STATUS_ON_LOAD,
    READ_PERM,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {
    checkPerm,
    getDefaultQueryFields,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp,
    longTableColumn,
    mergeDeep,
    openInNewTab,
    resourceIsLoaded,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Button from '../../../common/components/button'
import {formatNumber} from "chart.js/helpers";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import CardTitle from "../../../common/components/card/card-title";
import Card from "../../../common/components/card";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import ModalDefault from "../../../common/components/modal/modal-default";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import WorkOrderStatusBadge from "../../../common/components/badge/work-order-status-badge";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import Tippy from "@tippyjs/react";
import {currentDate, toFrontDateTimeFromUTC} from "../../../common/util/util-dates";
import {getSecondResource} from "../../../data/actions/secondResource";
import {CheckCircleIcon, PlusIcon, TrashIcon} from "@heroicons/react/24/outline";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import {clearThirdResource, getThirdResource} from "../../../data/actions/thirdResource";
import CardSubTitle from "../../../common/components/card/card-sub-title";
import moment from "moment/moment";
import Env from "../../../util/env";
import {showGlobalModal} from "../../../data/actions/ui";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import {getUser} from "../../../common/util/util-auth";
import {clearDialogResource} from "../../../data/actions/dialogResource";

class ScheduledMaintenanceTab extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1) + "_scheduled"

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'ScheduledMaintenanceTypeID',
            sortWo: 'ASC',
            sortByWo: 'AutoCounter',
            offsetWo: 0,
            paginationPageWo: 1,
            contactsList: {},
            selectedContactsToAdd: {},
            selectedContacts: {},
            selectedTrucksRows: {},
            isTrailerModalOpen: false,
            selectedTrailerRows: {},

            // Modals
            createModalOpen: false,
            isAllContactsModalOpen: false,
            selectedWoItem: null,
            editModalOpen: false,
            canSubmit: false,
            // Fields
            fields: this.getFields(),
            assetFields: [],
            truckAssetFields: [],
            trailerAssetFields: [],
            isTruckModalOpen: false,
            createFields: this.getCreateFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {
                behaviour: {
                    canAdjustWidth: true,
                    hasVirtualRows: true,
                    hasMenu: true
                },
                style: {
                    condensed: true,
                    floatingActions: true,
                    isGPUAccelerated: true,
                    condensedHeight: 100
                },
                classes: {},
                columns: {
                    ScheduledMaintenanceTypeID: {
                        frozen: false
                    }
                }
            }, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),
            queryWoFields: this.getQueryWoFields(this.props.translate),
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (resourceIsLoaded(this.props.thirdResource, prevProps.thirdResource)) {
            const dataEdit = getProp(this.props.thirdResource, 'data', {})

            let contactsList = dataEdit.AlertContactIDs !== "" && dataEdit.AlertContactIDs.split(',');

            let result = contactsList.length ? contactsList.reduce((acc, num) => {
                acc[num] = {ContactID: num};
                return acc;
            }, {}) : {}

            const editFields = this.getEditFields(dataEdit)

            let assetsData = dataEdit.Assets;

            let truckAssetFields = assetsData.filter((x) => x.TruckID).map((it) => {
                if (it.TruckID) {
                    if (editFields?.OdometerInterval?.value && editFields?.IsByOdometer?.value) {
                        it.NextServiceOdometer = Number(it?.LastOdometerRun) + Number(editFields.OdometerInterval.value)
                    }

                    // if (editFields?.HoursInterval?.value && editFields?.IsByHours?.value) {
                    //     it.NextServiceHours = Number(it?.LastHoursRun) + Number(editFields.HoursInterval.value)
                    // }

                    if (editFields?.DateInterval?.value && editFields?.IsByDate?.value) {
                        it.NextServiceDate = moment(it?.LastDateRun).add(editFields.DateInterval.value, "days")
                    }

                    it.TruckNumber = it.Truck;

                    return this.getVehicleFields(it)
                }
            })

            let trailerAssetFields = assetsData.filter((x) => x.TrailerID).map((it) => {
                if (it.TrailerID) {
                    // if (editFields?.OdometerInterval?.value && editFields?.IsByOdometer?.value) {
                    //     it.NextServiceOdometer = Number(it?.LastOdometerRun) + Number(editFields.OdometerInterval.value)
                    // }

                    if (editFields?.HoursInterval?.value && editFields?.IsByHours?.value) {
                        it.NextServiceHours = Number(it?.LastHoursRun) + Number(editFields.HoursInterval.value)
                    }

                    if (editFields?.DateInterval?.value && editFields?.IsByDate?.value) {
                        it.NextServiceDate = moment(it?.LastDateRun).add(editFields.DateInterval.value, "days")
                    }

                    it.TrailerNumber = it.Trailer;

                    return this.getVehicleFields(it)
                }
            })

            this.setState({
                createFields: editFields,
                editModalOpen: !this.state.editModalOpen,
                assetFields: this.getCreateAssetFields(),
                contactsList: result,
                selectedContactsToAdd: result,
                truckAssetFields: truckAssetFields,
                trailerAssetFields: trailerAssetFields,
                selectedTruckRows:{},
                selectedTrailerRows: {}
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    fetchWoData = (item) => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.WorkOrderScheduledMaintenanceWorkOrders,
            query: this.getWoQuery(item)
        }))
    }

    fetchEditData = (item) => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.WorkOrderScheduledMaintenance,
            query: {ScheduledMaintenanceID: item?.ScheduledMaintenanceID}
        }))
    }

    archiveResource = (item) => {
        this.setState({text: this.props.translate('message.are_you_sure_archive_scheduled_maintenance')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.props.dispatch(deleteResource({
                    user: LocalStorage.get('user'),
                    query: Object.assign({
                        ScheduledMaintenanceID: item.ScheduledMaintenanceID
                    }, this.getQuery()),
                    errorMessage: true, successMessage: `${item.ScheduledMaintenanceID} deleted`,
                    resource: this.getResource(),
                    piggyResource: this.getResource()
                }))
                this.handleToggleConfirmDialog()
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: this.props.translate('message.are_you_sure_restore_scheduled_maintenance')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: {
                        ScheduledMaintenanceID: item.ScheduledMaintenanceID,
                        ArchivedDate: 1
                    },
                    errorMessage: true, successMessage: `Scheduled maintenance ${item.item.ScheduledMaintenanceID} restored`,
                    query: this.getQuery(),
                    resource: this.getResource(),
                    piggyResource: this.getResource()
                }))
                this.handleToggleConfirmDialog()
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    setCanSubmit = () => {
        this.setState({ canSubmit: true })
    }

    handleToggleWoDialog = (item) => {
        this.setState({
            showWoDialog: !this.state.showWoDialog,
            selectedWoItem: item
        })

        if (item?.ScheduledMaintenanceID) {
            this.fetchWoData(item);
        }
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterWoInputChange = (name, value) => {
        this.setState({
            queryWoFields: FieldsManager.updateField(this.state.queryWoFields, name, value),
            offsetWo: 0,
            paginationPageWo: 1
        }, () => {
            this.saveFilters()
            this.fetchWoData(this.state.selectedWoItem)
        })
    }

    handleCreateInputChange = (fields, name, value) => {
        let fieldsUpdate = FieldsManager.updateField(this.state.createFields, name, value)
        const truckAssetFields = this.state.truckAssetFields;
        const trailerAssetFields = this.state.trailerAssetFields;

        if (name === 'IsByDate') {
            fieldsUpdate.DateInterval.type = Number(value) === 1 ? 'integer' : 'hidden'
            fieldsUpdate.AlertBeforeDaysDue.type = Number(value) === 1 ? 'integer' : 'hidden'

            fieldsUpdate.DateInterval.value = Number(value) === 1 ? 90 : null
            fieldsUpdate.AlertBeforeDaysDue.value = ''

            fieldsUpdate.DateInterval.validate = Number(value) === 1 ? ['empty'] : ['']
            fieldsUpdate.AlertBeforeDaysDue.validate = Number(value) === 1 ? ['empty'] : ['']

            if (Number(value) === 1) {
                truckAssetFields.forEach(((item, index) => {
                    this.handleTruckVehicleFieldsInputChange('NextServiceDate', moment(item.LastDateRun.value).add(Number(fieldsUpdate.DateInterval.value), "days"), index)
                }))
            } else if (Number(value) === 0) {
                truckAssetFields.forEach(((item, index) => {
                    this.handleTruckVehicleFieldsInputChange('NextServiceDate', '', index)
                }))
            }

            if (Number(value) === 1) {
                trailerAssetFields.forEach(((item, index) => {
                    this.handleTrailerVehicleFieldsInputChange('NextServiceDate', moment(item.LastDateRun.value).add(Number(fieldsUpdate.DateInterval.value), "days"), index)
                }))
            } else if (Number(value) === 0) {
                trailerAssetFields.forEach(((item, index) => {
                    this.handleTrailerVehicleFieldsInputChange('NextServiceDate', '', index)
                }))
            }
        }

        if (name === 'IsByOdometer') {
            fieldsUpdate.OdometerInterval.type = Number(value) === 1 ? 'integer' : 'hidden'
            fieldsUpdate.AlertBeforeMilesDue.type = Number(value) === 1 ? 'integer' : 'hidden'

            fieldsUpdate.OdometerInterval.value = Number(value) === 1 ? 1500 : null
            fieldsUpdate.AlertBeforeMilesDue.value = ''

            fieldsUpdate.OdometerInterval.validate = Number(value) === 1 ? ['empty'] : ['']
            fieldsUpdate.AlertBeforeMilesDue.validate = Number(value) === 1 ? ['empty'] : ['']

            if (Number(value) === 1) {
                truckAssetFields.forEach(((item, index) => {
                    !!item.TruckID.value && this.handleTruckVehicleFieldsInputChange('NextServiceOdometer', Number(item.LastOdometerRun.value ?? 0) + Number(fieldsUpdate.OdometerInterval.value), index)
                }))
            } else if (Number(value) === 0) {
                truckAssetFields.forEach(((item, index) => {
                    !!item.TruckID.value && this.handleTruckVehicleFieldsInputChange('NextServiceOdometer', 0, index)
                }))
            }

            // if (Number(value) === 1) {
            //     trailerAssetFields.forEach(((item, index) => {
            //         !!item.TrailerID.value && this.handleTrailerVehicleFieldsInputChange('NextServiceOdometer', Number(item.LastOdometerRun.value ?? 0) + Number(fieldsUpdate.OdometerInterval.value), index)
            //     }))
            // } else if (Number(value) === 0) {
            //     trailerAssetFields.forEach(((item, index) => {
            //         !!item.TrailerID.value && this.handleTrailerVehicleFieldsInputChange('NextServiceOdometer', 0, index)
            //     }))
            // }
        }

        if (name === 'IsByHours') {
            fieldsUpdate.HoursInterval.type = Number(value) === 1 ? 'integer' : 'hidden'
            fieldsUpdate.AlertBeforeHoursDue.type = Number(value) === 1 ? 'integer' : 'hidden'

            fieldsUpdate.HoursInterval.value = Number(value) === 1 ? 1000 : null
            fieldsUpdate.AlertBeforeHoursDue.value = ''

            fieldsUpdate.HoursInterval.validate = Number(value) === 1 ? ['empty'] : ['']
            fieldsUpdate.AlertBeforeHoursDue.validate = Number(value) === 1 ? ['empty'] : ['']

            // if (Number(value) === 1) {
            //     truckAssetFields.forEach(((item, index) => {
            //         !!item.TruckID.value && this.handleTruckVehicleFieldsInputChange('NextServiceHours', Number(item.LastHoursRun.value ?? 0) + Number(fieldsUpdate.HoursInterval.value), index)
            //     }))
            // } else if (Number(value) === 0) {
            //     truckAssetFields.forEach(((item, index) => {
            //         !!item.TruckID.value && this.handleTruckVehicleFieldsInputChange('NextServiceHours', 0, index)
            //     }))
            // }

            if (Number(value) === 1) {
                trailerAssetFields.forEach(((item, index) => {
                    !!item.TrailerID.value && this.handleTrailerVehicleFieldsInputChange('NextServiceHours', Number(item.LastHoursRun.value ?? 0) + Number(fieldsUpdate.HoursInterval.value), index)
                }))
            } else if (Number(value) === 0) {
                trailerAssetFields.forEach(((item, index) => {
                    !!item.TrailerID.value && this.handleTrailerVehicleFieldsInputChange('NextServiceHours', 0, index)
                }))
            }
        }

        if (name === 'OdometerInterval') {
            truckAssetFields.forEach(((item, index) => {
                !!item.TruckID.value && this.handleTruckVehicleFieldsInputChange('NextServiceOdometer', (value ? Number(item.LastOdometerRun.value) : 0) + Number(value) ?? 0, index)
            }))

            // trailerAssetFields.forEach(((item, index) => {
            //     !!item.TrailerID.value && this.handleTrailerVehicleFieldsInputChange('NextServiceOdometer', (value ? Number(item.LastOdometerRun.value) : 0) + Number(value) ?? 0, index)
            // }))
        }

        if (name === 'HoursInterval') {
            // truckAssetFields.forEach(((item, index) => {
            //     !!item.TruckID.value && this.handleTruckVehicleFieldsInputChange('NextServiceHours', (value ? Number(item.LastHoursRun.value) : 0) + Number(value) ?? 0, index)
            // }))

            trailerAssetFields.forEach(((item, index) => {
                !!item.TrailerID.value && this.handleTrailerVehicleFieldsInputChange('NextServiceHours', (value ? Number(item.LastHoursRun.value) : 0) + Number(value) ?? 0, index)
            }))
        }

        if (name === 'DateInterval') {
            truckAssetFields.forEach(((item, index) => {
                this.handleTruckVehicleFieldsInputChange('NextServiceDate', moment(value ? item.LastDateRun.value : null).add(Number(value) ?? 0, "days"), index)
            }))

            trailerAssetFields.forEach(((item, index) => {
                this.handleTrailerVehicleFieldsInputChange('NextServiceDate', moment(value ? item.LastDateRun.value : null).add(Number(value) ?? 0, "days"), index)
            }))
        }

        this.setCanSubmit();

        return fieldsUpdate
    }

    handleAssetsFieldsInputChange = (name, value) => {
        let removedDifferenceTruck = {};
        let addedDifferenceTruck = {};
        let removedDifferenceTrailer = {};
        let addedDifferenceTrailer = {};

        if (name === "TruckIDs") {
            removedDifferenceTruck = this.state.assetFields.TruckIDs?.value && Object.values(this.state.assetFields.TruckIDs.value).filter(x => !value?.includes(x));
            addedDifferenceTruck = value?.filter(x => !(this.state.assetFields.TruckIDs?.value && Object.values(this.state.assetFields.TruckIDs.value)?.includes(x)));
        }

        if (name === "TrailerIDs") {
            removedDifferenceTrailer = this.state.assetFields.TrailerIDs?.value && Object.values(this.state.assetFields.TrailerIDs.value).filter(x => !value?.includes(x));
            addedDifferenceTrailer = value?.filter(x => !(this.state.assetFields.TrailerIDs?.value && Object.values(this.state.assetFields.TrailerIDs.value)?.includes(x)));
        }

        if (addedDifferenceTruck?.length) {
            this.setState((prevState) => ({
                truckAssetFields: [...prevState.truckAssetFields, this.getVehicleFields(addedDifferenceTruck[0].metadata)]
            }))
        }

        if (removedDifferenceTruck?.length) {
            this.setState(prevState => ({
                truckAssetFields: prevState.truckAssetFields.filter(item => item.value !== removedDifferenceTruck[0].value)
            }))

            this.setState(prevState => {
                const newArray = [...prevState.truckAssetFields];
                const index = newArray.findIndex(it => it.TruckID.value === removedDifferenceTruck[0].value);

                if (index > -1) {
                    newArray.splice(index, 1);
                }

                return {truckAssetFields: newArray};
            });
        }

        if (addedDifferenceTrailer?.length) {
            this.setState((prevState) => ({
                trailerAssetFields: [...prevState.trailerAssetFields, this.getVehicleFields(addedDifferenceTrailer[0].metadata)]
            }))
        }

        if (removedDifferenceTrailer?.length) {
            this.setState(prevState => ({
                trailerAssetFields: prevState.trailerAssetFields.filter(item => item.value !== removedDifferenceTrailer[0].value)
            }))


            this.setState(prevState => {
                const newArray = [...prevState.trailerAssetFields];
                const index = newArray.findIndex(it => it.TrailerID.value === removedDifferenceTrailer[0].value);

                if (index > -1) {
                    newArray.splice(index, 1);
                }

                return {trailerAssetFields: newArray};
            });
        }
        this.setState({
            assetFields: FieldsManager.updateField(this.state.assetFields, name, value),
            canSubmit: true
        });
    }

    handleTruckVehicleFieldsInputChange = (name, value, index) => {
        if (name === "LastDateRun" && !!value) {
            if (Number(this.state.createFields?.IsByDate?.value) === 1) {
                this.handleTruckVehicleFieldsInputChange('NextServiceDate', moment(value, 'YYYY-MM-DD').add(this.state.createFields?.DateInterval?.value, "days"), index)
            }
        }

        if (name === "LastOdometerRun" && !!value) {
            if (Number(this.state.createFields?.IsByOdometer?.value) === 1) {
                this.handleTruckVehicleFieldsInputChange('NextServiceOdometer', Number(value) + Number(this.state.createFields?.OdometerInterval?.value), index)
            }
        }

        // if (name === "LastHoursRun" && !!value) {
        //     if (Number(this.state.createFields?.IsByHours?.value) === 1) {
        //         this.handleTruckVehicleFieldsInputChange('NextServiceHours', Number(value) + Number(this.state.createFields?.HoursInterval?.value), index)
        //     }
        // }

        this.setState((prevState) => ({
            ...prevState.truckAssetFields,
            [index]: FieldsManager.updateField(this.state.truckAssetFields[index], name, value),
            canSubmit: true
        }))
    }

    handleTrailerVehicleFieldsInputChange = (name, value, index) => {
        if (name === "LastDateRun" && !!value) {
            if (Number(this.state.createFields?.IsByDate?.value) === 1) {
                this.handleTrailerVehicleFieldsInputChange('NextServiceDate', moment(value, 'YYYY-MM-DD').add(this.state.createFields?.DateInterval?.value, "days"), index)
            }
        }

        // if (name === "LastOdometerRun" && !!value) {
        //     if (Number(this.state.createFields?.IsByOdometer?.value) === 1) {
        //         this.handleTrailerVehicleFieldsInputChange('NextServiceOdometer', Number(value) + Number(this.state.createFields?.OdometerInterval?.value), index)
        //     }
        // }

        if (name === "LastHoursRun" && !!value) {
            if (Number(this.state.createFields?.IsByHours?.value) === 1) {
                this.handleTrailerVehicleFieldsInputChange('NextServiceHours', Number(value) + Number(this.state.createFields?.HoursInterval?.value), index)
            }
        }

        this.setState((prevState) => ({
            ...prevState.trailerAssetFields,
            [index]: FieldsManager.updateField(this.state.trailerAssetFields[index], name, value),
            canSubmit: true
        }))
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {
            behaviour: {
                canAdjustWidth: true,
                hasVirtualRows: true,
                hasMenu: true
            },
            columns: {
                ScheduledMaintenanceTypeID: {frozen: false}
            },
            classes: {}
        }, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    setOptions = (options) => {
        let newOptions = options;
        newOptions.style = {
            ...newOptions.style,
            condensed: true,
            condensedHeight: 100
        }
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleToggleCreateModal = () => {
        this.props.dispatch(clearThirdResource());

        this.setState({
            createModalOpen: !this.state.createModalOpen,
            assetFields: this.getCreateAssetFields(),
            contactsList: {},
            truckAssetFields: [],
            trailerAssetFields: [],
            createFields: this.getCreateFields(),
            canSubmit: false
        })
    }

    handleToggleEditModal = (item) => {
        if (item) {
            this.fetchEditData(item)
        }
    }

    handleToggleEditModalClose = () => {
        this.setState({
            createFields: this.getCreateFields(),
            editModalOpen: false,
            contactsList: {},
            selectedContactsToAdd: {},
            selectedTruckRows:{},
            selectedTrailerRows: {},
            canSubmit: false
        })
        this.props.dispatch(clearThirdResource());
    }

    handleToggleAllContactsModal = () => {
        this.setState({
            isAllContactsModalOpen: !this.state.isAllContactsModalOpen,
            selectedContactsToAdd: this.state.contactsList
        })
    }

    handleSelectContact = (row, id) => {

        const isAddContacts = this.state.isAllContactsModalOpen
        const selectedContactsToAdd = cloneDeep(this.state.selectedContactsToAdd);
        const selectedContacts = cloneDeep(this.state.selectedContacts);

        if (isAddContacts) {
            if (selectedContactsToAdd[id]) {
                delete selectedContactsToAdd[id]
            } else {
                selectedContactsToAdd[id] = row
            }
        } else {
            if (selectedContacts[id]) {
                delete selectedContacts[id]
            } else {
                selectedContacts[id] = row
            }
        }

        this.setState({
            selectedContactsToAdd,
            selectedContacts
        })
    }

    handleSelectAllContacts = (areAllSelected) => {

        const isAddContacts = this.state.isAllContactsModalOpen
        const prop = getProp(this.props.dialogResource, 'data.list', [])

        const selectedContactsToAdd = cloneDeep(this.state.selectedContactsToAdd);
        const selectedContacts = cloneDeep(this.state.selectedContacts);

        prop.map(item => {
            if (!areAllSelected) {
                isAddContacts ?
                    selectedContactsToAdd[item.ContactID] = item
                    :
                    selectedContacts[item.ContactID] = item;
            } else {
                isAddContacts ?
                    delete selectedContactsToAdd[item.ContactID]
                    :
                    delete selectedContacts[item.ContactID]
            }
        });

        this.setState({
            selectedContactsToAdd,
            selectedContacts
        });
    }

    handleAddContacts = () => {
        const Contacts = this.state.selectedContactsToAdd
        this.setState({contactsList: Contacts})
        this.handleToggleAllContactsModal()
    }

    toggleTruckSelectDialog = () => {
        let TrucksList = this.state.assetFields.TruckIDs.value;

        let result = TrucksList?.length ? TrucksList.reduce((acc, num) => {
            acc[num.value] = num.metadata
            return acc;
        }, {}) : {}


        this.setState({
            isTruckModalOpen: !this.state.isTruckModalOpen,
            selectedTrucksRows: result
        })
    }

    toggleTrailerSelectDialog = () => {
        let TrailersList = this.state.assetFields.TrailerIDs.value;

        let result = TrailersList?.length ? TrailersList.reduce((acc, num) => {
            acc[num.value] = num.metadata
            return acc;
        }, {}) : {}


        this.setState({
            isTrailerModalOpen: !this.state.isTrailerModalOpen,
            selectedTrailerRows: result
        })
    }

    handleSelectRowClickTruck = (item) => {
        const itemID = item.TruckID
        let selectedRows = Object.assign({}, this.state.selectedTrucksRows);


        if (selectedRows[itemID]) {
            delete selectedRows[itemID]
        } else {
            Object.assign(selectedRows, {[itemID]: item})
        }

        this.setState({selectedTrucksRows: selectedRows})
    }

    handleSelectAllClickTruck = (selectAll) => {
        const data = cloneDeep(getProp(this.props.dialogResource.data, 'list', []))

        let selectedRows = Object.assign({}, this.state.selectedTrucksRows);


        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                selectedRows[it.TruckID] = it
                memo[it.TruckID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.TruckID.toString())) {
                    delete selectedRows[it.TruckID]
                }
            })
        }

        this.setState({selectedTrucksRows: selectedRows})
    }

    handleSelectRowClickTrailer = (item) => {
        const itemID = item.TrailerID
        let selectedRows = Object.assign({}, this.state.selectedTrailerRows);

        if (selectedRows[itemID]) {
            delete selectedRows[itemID]
        } else {
            Object.assign(selectedRows, {[itemID]: item})
        }

        this.setState({selectedTrailerRows: selectedRows})
    }

    handleSelectAllClickTrailer = (selectAll) => {
        const data = cloneDeep(getProp(this.props.dialogResource.data, 'list', []))

        let selectedRows = Object.assign({}, this.state.selectedTrailerRows);


        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                selectedRows[it.TrailerID] = it
                memo[it.TrailerID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.TrailerID.toString())) {
                    delete selectedRows[it.TrailerID]
                }
            })
        }

        this.setState({selectedTrailerRows: selectedRows})
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'input', 'archived'])
    }

    getFields = () => {
        return {
            ScheduledMaintenanceTypeID: new Field('ScheduledMaintenanceTypeID', '', [''], false, 'select'),
            ScheduleNotes: new Field('ScheduleNotes', '', [''], false, 'textarea'),
            ScheduledInterval: new Field('ScheduledInterval', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <div className={"grid grid-rows-3"}>
                            <> {!!item.IsByDate && (
                                <div className={"row-start-1"}>
                                    <button
                                        className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                    >
                                        <span>Every {item.DateInterval} days</span>
                                    </button>
                                    <br/>
                                </div>
                            )}

                                {!!item.IsByOdometer && (
                                    <div className={"row-start-2"}>
                                        <button
                                            className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                        >
                                            <span>Every {formatNumber(item.OdometerInterval, undefined)} mil.</span>
                                        </button>
                                        <br/>
                                    </div>
                                )}

                                {!!item.IsByHours && (
                                    <div className={"row-start-3"}>
                                        <button
                                            className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                        >
                                            <span>Every {item.HoursInterval} engine hours</span>
                                        </button>
                                        <br/>
                                    </div>
                                )}
                            </>
                        </div>
                    )
                }
            }),
            AlertBefore: new Field('AlertBefore', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <div className={"grid grid-rows-3"}>
                            {!!item.IsByDate && (
                                <div className={"row-start-1"}>
                                    <button
                                        className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                    >
                                        <span>{item.AlertBeforeDaysDue} days before</span>
                                    </button>
                                    <br/>
                                </div>
                            )}
                            {!!item.IsByOdometer && (
                                <div className={"row-start-2"}>
                                    <button
                                        className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                    >
                                        <span>{formatNumber(item.AlertBeforeMilesDue, undefined)} miles before</span>
                                    </button>
                                    <br/>
                                </div>
                            )}
                            {!!item.IsByHours && (
                                <div className={"row-start-3"}>
                                    <button
                                        className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                    >
                                        <span>{item.AlertBeforeHoursDue} hours before</span>
                                    </button>
                                    <br/>
                                </div>
                            )}
                        </div>
                    )
                }
            }),
            AssetCount: new Field('AssetCount', '', [''], false, 'custom', {
                render: (item) => {
                    return (<div className={"flex ml-4"}>{item.AssetCount}</div>)
                }
            })
        }
    }

    getCreateFields = () => {
        return {
            ScheduledMaintenanceTypeID: new Field('ScheduledMaintenanceTypeID', '', ['empty'], false, 'select', {
                addContainerClass: "col-span-full max-w-[300px]"
            }),
            ScheduleNotes: new Field('ScheduleNotes', '', [''], false, 'textarea', {
                addContainerClass: "col-span-full"
            }),
            IsByDate: new Field('IsByDate', '', [''], false, 'checkbox', {
                addContainerClass: "col-span-2",
                labelType: "float"
            }),
            DateInterval: new Field('DateInterval', '', [''], false, 'hidden', {addContainerClass: "col-span-2 sm:col-span-1"}),
            AlertBeforeDaysDue: new Field('AlertBeforeDaysDue', '', [''], false, 'hidden', {addContainerClass: "col-span-2 sm:col-span-1"}),
            IsByOdometer: new Field('IsByOdometer', '', [''], false, 'checkbox', {
                addContainerClass: "col-span-2",
                labelType: "float"
            }),
            OdometerInterval: new Field('OdometerInterval', '', [''], false, 'hidden', {addContainerClass: "col-span-2 sm:col-span-1"}),
            AlertBeforeMilesDue: new Field('AlertBeforeMilesDue', '', [''], false, 'hidden', {addContainerClass: "col-span-2 sm:col-span-1"}),
            IsByHours: new Field('IsByHours', '', [''], false, 'checkbox', {
                addContainerClass: "col-span-2",
                labelType: "float"
            }),
            HoursInterval: new Field('HoursInterval', '', [''], false, 'hidden', {addContainerClass: "col-span-2 sm:col-span-1"}),
            AlertBeforeHoursDue: new Field('AlertBeforeHoursDue', '', [''], false, 'hidden', {addContainerClass: "col-span-2 sm:col-span-1"})
        }
    }

    getEditFields = (item = {}) => {
        let fieldTemplates = {
            ScheduledMaintenanceID: new Field('ScheduledMaintenanceID', '', [''], false, 'hidden'),
            ScheduledMaintenanceTypeID: new Field('ScheduledMaintenanceTypeID', '', ['empty'], false, 'select', {
                addContainerClass: "col-span-full max-w-[300px]"
            }),
            ScheduleNotes: new Field('ScheduleNotes', '', [''], false, 'textarea', {
                addContainerClass: "col-span-full"
            }),
            IsByDate: new Field('IsByDate', '', [''], false, 'checkbox', {
                addContainerClass: "col-span-2",
                labelType: "float"
            }),
            DateInterval: new Field('DateInterval', '', Number(item.IsByDate) === 1 ? ['empty'] : [''], false, Number(item.IsByDate) === 1 ? 'integer' : 'hidden', {addContainerClass: "col-span-2 sm:col-span-1"}),
            AlertBeforeDaysDue: new Field('AlertBeforeDaysDue', '', Number(item.IsByDate) === 1 ? ['empty'] : [''], false, Number(item.IsByDate) === 1 ? 'integer' : 'hidden', {addContainerClass: "col-span-2 sm:col-span-1"}),
            IsByOdometer: new Field('IsByOdometer', '', [''], false, 'checkbox', {
                addContainerClass: "col-span-2",
                labelType: "float"
            }),
            OdometerInterval: new Field('OdometerInterval', '', Number(item.IsByOdometer) === 1 ? ['empty'] : [''], false, Number(item.IsByOdometer) === 1 ? 'integer' : 'hidden', {addContainerClass: "col-span-2 sm:col-span-1"}),
            AlertBeforeMilesDue: new Field('AlertBeforeMilesDue', '', Number(item.IsByOdometer) === 1 ? ['empty'] : [''], false, Number(item.IsByOdometer) === 1 ? 'integer' : 'hidden', {addContainerClass: "col-span-2 sm:col-span-1"}),
            IsByHours: new Field('IsByHours', '', [''], false, 'checkbox', {
                addContainerClass: "col-span-2",
                labelType: "float"
            }),
            HoursInterval: new Field('HoursInterval', '', Number(item.IsByHours) === 1 ? ['empty'] : [''], false, Number(item.IsByHours) === 1 ? 'integer' : 'hidden', {addContainerClass: "col-span-2 sm:col-span-1"}),
            AlertBeforeHoursDue: new Field('AlertBeforeHoursDue', '', Number(item.IsByHours) === 1 ? ['empty'] : [''], false, Number(item.IsByHours) === 1 ? 'integer' : 'hidden', {addContainerClass: "col-span-2 sm:col-span-1"})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getCreateAssetFields = (TrucksData, TrailersData) => {
        const dataEdit = getProp(this.props.thirdResource, 'data', {})
        let TrucksIDsValues = dataEdit.Assets?.filter(x => x.TruckID);
        let TrailerIDsValues = dataEdit.Assets?.filter(x => x.TrailerID);

        if (dataEdit) {
            if (TrucksIDsValues) {
                TrucksIDsValues = TrucksIDsValues.map((item) => ({
                    value: item.TruckID,
                    label: item.Truck,
                    metadata: item
                }))
            }

            if (TrailerIDsValues) {
                TrailerIDsValues = TrailerIDsValues.map((item) => ({
                    value: item.TrailerID,
                    label: item.Trailer,
                    metadata: item
                }))
            }
        }


        if (TrucksData) {
            TrucksIDsValues = Object.values(TrucksData).map((item) => ({
                value: item.TruckID,
                label: item.Truck,
                metadata: item
            }))
        }

        if (TrailersData) {
            TrailerIDsValues = Object.values(TrailersData).map((item) => ({
                value: item.TrailerID,
                label: item.Trailer,
                metadata: item
            }))
        }

        return {
            TruckIDs: new Field('TruckIDs', TrucksIDsValues, [''], false, 'select-search',
                {htmlAfterField: () => {
                    return <button
                        onClick={() => this.toggleTruckSelectDialog()}
                        className="btn btn-input"
                    >
                        <PlusIcon/>
                    </button>
                }}
                // {}
                , {
                    multi: true,
                    all: true
                }),
            TrailerIDs: new Field('TrailerIDs', TrailerIDsValues, [''], false, 'select-search', {htmlAfterField: () => {
            return <button
                onClick={() => this.toggleTrailerSelectDialog()}
                className="btn btn-input"
            >
                <PlusIcon/>
            </button>
        }}, {
                multi: true,
                all: true
            })
        }
    }

    getVehicleFields = (data) => {
        const createFields = this.state.createFields;

        let fieldTemplates = {
            TruckID: new Field('TruckID', data?.TruckID, [''], false, 'hidden'),
            TrailerID: new Field('TrailerID', data?.TrailerID, [''], false, 'hidden'),
            TruckNumber: new Field('TruckNumber', data?.TruckID && data?.TruckNumber, [''], false, 'hidden', {addContainerClass: "col-span-1"}),
            TrailerNumber: new Field('TrailerNumber', data?.TrailerID && data?.TrailerNumber, [''], false, 'hidden', {addContainerClass: "col-span-1"}),
            LastDateRun: new Field('LastDateRun', data?.LastDateRun ?? currentDate(), [''], false, 'date', {addContainerClass: "col-start-2 col-span-1"}),
            CurrentDate: new Field('CurrentDate', currentDate(), [''], true, 'date', {addContainerClass: "col-start-3 col-span-1"}),
            NextServiceDate: new Field('NextServiceDate', data?.NextServiceDate ?? '', [''], true, 'date', {addContainerClass: "col-span-1"}),
            ...(!!data?.TruckID && {
                LastOdometerRun: new Field('LastOdometerRun', data?.LastOdometerRun, [''], false, 'int', {addContainerClass: "col-start-2 col-span-1"}),
                CurrentMileage: new Field('CurrentMileage', data?.TruckID && data.Mileage, [''], true, 'int', {addContainerClass: "col-start-3 col-span-1"}),
                NextServiceOdometer: new Field('NextServiceOdometer', data?.NextServiceOdometer ?? '', [''], true, 'int', {addContainerClass: "col-span-1"}),
            }),
            ...(!!data?.TrailerID && {
                LastHoursRun: new Field('LastHoursRun', data?.LastHoursRun, [''], false, 'int', {addContainerClass: "col-start-2 col-span-1"}),
                CurrentHours: new Field('CurrentHours', data?.TrailerID && (data.Mileage ?? data.EngineHours), [''], true, 'int', {addContainerClass: "col-start-3 col-span-1"}),
                NextServiceHours: new Field('NextServiceHours', data?.NextServiceHours ?? '', [''], true, 'int', {addContainerClass: "col-span-1"}),
            }),
            // CompanyID: new Field('CompanyID', data?.CompanyID ?? null, [''], false, 'hidden'),
            // CreateUpdateDate: new Field('CreateUpdateDate', data?.CreateUpdateDate ?? null, [''], false, 'hidden'),
            ScheduledMaintenanceAssetID: new Field('ScheduledMaintenanceAssetID', data?.ScheduledMaintenanceAssetID ?? '', [''], false, 'hidden'),
            ScheduledMaintenanceID: new Field('ScheduledMaintenanceID', data?.ScheduledMaintenanceID ?? '', [''], false, 'hidden')
        }


        if (createFields?.OdometerInterval?.value && createFields?.IsByOdometer?.value && !!data?.TruckID) {
            fieldTemplates.NextServiceOdometer.value = Number(createFields.OdometerInterval.value)
        }

        if (createFields?.HoursInterval?.value && createFields?.IsByHours?.value && !!data?.TrailerID) {
            fieldTemplates.NextServiceHours.value = Number(createFields.HoursInterval.value)
        }

        if (createFields?.DateInterval?.value && createFields?.IsByDate?.value) {
            fieldTemplates.NextServiceDate.value = moment().add(createFields.DateInterval.value, "days")
        }


        return fieldTemplates
    }

    getWoFields = (item = {}) => {
        const TruckID = !!item?.TruckID;
        const TrailerID = !!item?.TrailerID;

        const workOrderStatuses = getLookup("WorkOrderStatus") ?? {};

        const CheckboxCell = ({value}) => {
            return value ? <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
                : <XMarkIcon className="w-5 h-5 text-tm-gray-400 inline"/>
        }

        const fieldTemplates = {
            AutoCounter: new Field('AutoCounter', '', [''], false, 'custom', {
                render: (it) => {
                    return (
                        <CellButton
                            onClick={() => checkPerm(Resources.WorkOrders, UPDATE_PERM) && openInNewTab(Env.getTruckWorkOrdersUrl("/work-orders/" + it?.WorkOrderID))}
                            disabled={!checkPerm(Resources.WorkOrders, UPDATE_PERM)}
                        >
                            {it.AutoCounter}
                        </CellButton>
                    )
                }
            }),
            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
                , omitSort: true
            }),
            WorkOrderStatusID: new Field('WorkOrderStatusID', '', [''], false, 'select', {
                render: (it) => <WorkOrderStatusBadge
                    workOrderStatuses={workOrderStatuses}
                    statusID={it.WorkOrderStatusID}
                />
            }),
            TruckID: new Field('TruckID', '', TrailerID ? [''] : ['empty'], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TruckID) {
                        return (
                            <CellLink
                                to={`/trucks/info/${item.TruckID}`}
                                openInNewTab={true}
                                disabled={!checkPerm(Resources.TrucksInfo, READ_PERM)}
                            >
                                {item.Truck}
                            </CellLink>
                        )
                    } else {
                        return (<span>{item.Truck}</span>)
                    }
                },
                addContainerClass: "col-span-6 col-start-1"
            }, {isClearable: true}),
            TrailerID: new Field('TrailerID', '', TruckID ? [''] : ['empty'], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TrailerID) {
                        return (
                            <CellLink
                                to={`/trailers/info/${item.TrailerID}`}
                                openInNewTab={true}
                                disabled={!checkPerm(Resources.Trailer, READ_PERM)}
                            >
                                {item.Trailer}
                            </CellLink>
                        )
                    } else {
                        return item.Trailer;
                    }
                },
                addContainerClass: "col-span-6"
            }, {isClearable: true}),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                label: "ChargeTo",
                render: (item) => {
                    if (item.ChargeTo == 2) {
                        return ("Non-Chargeable")
                    }
                    if (item.ChargeContactID) {
                        return <CellButton
                            // onClick={() => checkPerm(Resources.Contacts, READ_PERM) && handleViewContactClick(item.ChargeContactID)}
                        >
                            {item.ChargeContact}
                        </CellButton>
                    }
                    if (item.ChargeOrganizationID) {
                        return <CellButton
                            // onClick={() => checkPerm(Resources.Contacts, READ_PERM) && handleViewOrganizationClick(item.ChargeOrganizationID)}
                        >
                            {item.ChargeOrganization}
                        </CellButton>
                    }
                }
            }),
            Title: new Field('Title', "", [''], false, 'custom', {
                disableColumnFilter: true,
                label: "LastStopUpdate",
                render: (item) => {
                    if (!item.LastTitleNotes) {
                        return null;
                    }
                    if (item.LastNotes) {
                        return (
                            <Tippy
                                content={<span>{item.LastNotes}</span>}
                            >
                                <span>
                                    {item.LastTitleNotes} ({toFrontDateTimeFromUTC(item.LastNoteDateTime)})
                                </span>
                            </Tippy>
                        )
                    }
                    return (
                        <span>
                            {item.Title}
                        </span>
                    )
                }
            }),
            InitialComplaint: new Field('InitialComplaint', '', [''], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.InitialComplaint)}</div>
                )
            }),
            Notes: new Field('Notes', '', [''], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.Notes)}</div>
                )
            }),
            TotalAmount: new Field('TotalAmount', '', [''], false, "float", {
                render: (it) => (
                    <Tippy
                        content={
                            <div className={"text-right"}>
                                <div>Labor: {genericMoneyFormatter(it.LaborAmount)}</div>
                                <div>Parts: {genericMoneyFormatter(it.PartsAmount)}</div>
                                <div>Shop supplies: {genericMoneyFormatter(it.MiscAmount)}</div>
                                <div>Misc. surcharge: {genericMoneyFormatter(it.SurchargeAmount)}</div>
                            </div>
                        }
                    >
                        <div className={"text-right"}>
                            {genericMoneyFormatter(it.TotalAmount)}
                        </div>
                    </Tippy>
                ),
                colFilter: true
            }, {icon: false}),
            IsInvoiced: new Field('IsInvoiced', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsInvoiced}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsAuthorized: new Field('IsAuthorized', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsAuthorized}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            CreatedDate: new Field('CreatedDate', '', [], false, 'datetimezone'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'}),
            ScheduledMaintenanceTypeID: new Field('ScheduledMaintenanceTypeID', '', [''], false, 'select', {}, {isClearable: true}),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            TruckIDs: new Field('TruckIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                all: true
            }),
            TrailerIDs: new Field('TrailerIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                all: true
            })
        }
    }

    getQueryWoFields = (translate) => {
        return (
            Object.assign(
                getDefaultQueryFields("WorkOrderID", translate),
                {
                    archived: new Field('archived', '', [''], false, 'checkbox', {
                        labelType: "float",
                        label: "archived",
                        hasActiveBadge: true,
                    }),
                    StartDate: new Field('StartDate', '', [''], false, 'date', {hasActiveBadge: true}, {isClearable: true}),
                    EndDate: new Field('EndDate', '', [''], false, 'date', {hasActiveBadge: true}, {isClearable: true}),
                    WorkOrderStatusID: new Field('WorkOrderStatusID', '', [], false, 'select', {hasActiveBadge: true}, {all: true}),
                    TruckID: new Field('TruckID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                    TrailerID: new Field('TrailerID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                    IsInvoiced: new Field('IsInvoiced', '', [], false, 'select', {
                        hasActiveBadge: true,
                        activeFilterLabel: "invoiced"
                    }, {isClearable: true}),
                    IsAuthorized: new Field('IsAuthorized', '', [], false, 'select', {
                        hasActiveBadge: true,
                        activeFilterLabel: "authorized"
                    }, {isClearable: true}),
                    ChargeContactID: new Field('ChargeContactID', '', [], false, 'select-search', {
                        hasActiveBadge: true,
                        label: "ChargeContact"
                    }, {isClearable: true}),
                    ChargeOrganizationID: new Field('ChargeOrganizationID', '', [], false, 'select-search', {
                        hasActiveBadge: true,
                        label: "ChargeOrganisation"
                    }, {isClearable: true}),
                    OfficeID: new Field('OfficeID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                    ContactGroupID: new Field('ContactGroupID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                    ShowOnlyCompleted: new Field('ShowOnlyCompleted', '', [''], false, 'checkbox', {hasActiveBadge: true}, {}),
                    ShowOnlyActive: new Field('ShowOnlyActive', '', [''], false, 'checkbox', {hasActiveBadge: true}, {}),
                }
            )
        )
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getWoQuery = (item) => {
        return {
            sort: this.state.sortWo,
            sortBy: this.state.sortByWo,
            offset: this.state.offsetWo,
            ScheduledMaintenanceID: item?.ScheduledMaintenanceID,
            TruckID: item?.TruckID,
            TrailerID: item?.TrailerID,

            ...FieldsManager.getFieldKeyValues(this.state.queryWoFields)
        }
    }

    getResource = () => {
        return Resources.WorkOrderScheduledMaintenance
    }

    getTruckFields = () => ({
        TruckNumber: new Field('TruckNumber', '', [''], false, "text",),
        Manufacturer: new Field('Manufacturer', '', [''], false, "text",),
        Model: new Field('Model', '', [''], false, "text",),
        Year: new Field('Year', '', [''], false, "text", {label: "model_year"}),
        Engine: new Field('Engine', '', [''], false, "text"),
        VIN: new Field('VIN', '', [''], false, "text"),
        OwnedBy: new Field('OwnedBy', '', [''], false, "custom", {
            omitSort: true,
            render: (it) => {
                return (
                    <div>
                        {it.OwnedByOrganization ?? it.OwnedByContact}
                    </div>
                )
            }
        })
    });

    getTrailerFields = () => ({
        TrailerNumber: new Field('TrailerNumber', '', [''], false, "text",),
        Manufacturer: new Field('Manufacturer', '', [''], false, "text",),
        Model: new Field('Model', '', [''], false, "text",),
        Year: new Field('Year', '', [''], false, "text", {label: "model_year"}),
        Engine: new Field('Engine', '', [''], false, "text"),
        VIN: new Field('VIN', '', [''], false, "text"),
        OwnedBy: new Field('OwnedBy', '', [''], false, "custom", {
            omitSort: true,
            render: (it) => {
                return (
                    <div>
                        {it.OwnedByOrganization ?? it.OwnedByContact}
                    </div>
                )
            }
        })
    });

    getAssetFilters = () => (Object.assign({
            query: new Field('query', '', [''], false, 'search', {
                addContainerClass: 'col-span-3',
                labelType: "float"
            }),
            StatusID: new Field('StatusID', '', [''], false, 'select', {
                addContainerClass: 'col-span-3',
                labelType: "float"
            }, {isClearable: true}),
        },
    ));

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, secondResource} = this.props
        const data = getProp(resource.data, 'list', [])
        const secondData = getProp(secondResource.data, 'list', [])
        const isSecondLoading = getProp(secondResource, 'isLoading', true);
        const count = getProp(resource.data, 'count', 0)
        const secondCount = getProp(secondResource.data, 'count', 0)

        const contactTableOptions = {
            style: {
                stripedRows: true,
                horizontalLines: false,
                floatingActions: false,
            },
        }

        const getAssetSelects = {
            StatusID: {
                [DRIVER_STATUS_AVAILABLE]: "Available",
                [DRIVER_STATUS_BOOKED]: "Booked",
                [DRIVER_STATUS_ON_LOAD]: "On Load"
            },
            OfficeID: {
                api: 'api/' + Resources.Offices,
                query: {},
                searchMap: (item) => ({
                    value: item.OfficeID,
                    label: item.OfficeName,
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
        }

        const selects = {
            TruckIDs: {
                api: 'api/' + Resources.TrucksQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.TruckID,
                    label: item.Truck,
                    metadata: item
                })
            },
            TrailerIDs: {
                api: 'api/' + Resources.TrailersQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.TrailerID,
                    label: item.Trailer,
                    metadata: item
                })
            }
        }

        const fieldsAssetsToHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.assetFields)), translate, (name, value) => this.handleAssetsFieldsInputChange(name, value), selects)

        const fieldsTruckAssetFields = Object.values(this.state.truckAssetFields) && Object.values(this.state.truckAssetFields).map((vehicle, index) => {
                return (<Card key={index} addClass={'relative col-span-3'}
                              bodyClass={"grid grid-cols-4 gap-2 py-3 px-6 space-y-4"}>
                    <button
                        onClick={() => {
                            let removedDifferenceTruck = {};
                            if (vehicle.TruckID.value) {
                                removedDifferenceTruck = this.state.assetFields.TruckIDs?.value && Object.values(this.state.assetFields.TruckIDs.value).filter(x => x.value !== vehicle?.TruckID?.value);

                                this.handleAssetsFieldsInputChange("TruckIDs", removedDifferenceTruck)
                            }
                        }}
                        className="btn absolute right-2 top-1"
                    >
                        <TrashIcon className="w-4 h-5"/>
                    </button>

                    <CardSubTitle
                        text={vehicle?.TruckID?.value && (vehicle?.TruckNumber?.value + " (Truck)")}
                    />

                    {fieldsToHtml(Object.values(Object.assign({}, vehicle)), translate, (name, value) => this.handleTruckVehicleFieldsInputChange(name, value, index), selects)}
                </Card>)
            }
        )

        const fieldsTrailerAssetFields = Object.values(this.state.trailerAssetFields) && Object.values(this.state.trailerAssetFields).map((vehicle, index) => {
                return (<Card key={index} addClass={'relative col-span-3'}
                              bodyClass={"grid grid-cols-4 gap-2 py-3 px-6 space-y-4"}>
                    <button
                        onClick={() => {
                            let removedDifferenceTrailer = {};
                            if (vehicle.TrailerID.value) {
                                removedDifferenceTrailer = this.state.assetFields.TrailerIDs?.value && Object.values(this.state.assetFields.TrailerIDs.value).filter(x => x.value !== vehicle?.TrailerID?.value);

                                this.handleAssetsFieldsInputChange("TrailerIDs", removedDifferenceTrailer)
                            }
                        }}
                        className="btn absolute right-2 top-1"
                    >
                        <TrashIcon className="w-4 h-5"/>
                    </button>

                    <CardSubTitle
                        text={vehicle?.TrailerID?.value && (vehicle?.TrailerNumber?.value + " (Trailer)")}
                    />

                    {fieldsToHtml(Object.values(Object.assign({}, vehicle)), translate, (name, value) => this.handleTrailerVehicleFieldsInputChange(name, value, index), selects)}
                </Card>)
            }
        )


        const {style: {condensed, condensedHeight, ...restStyle}, style, ...restParent} = this.state.tableOptions;

        const tableOptions = {
            ...restParent,
            style: restStyle
        };

        return (
            <React.Fragment>
                <div className="sm:flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="ml-auto flex sm:justify-start justify-end items-center">
                        <div className={'flex mr-4'}>
                            <TableSettingsPopOver
                                options={tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                        <Button
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={() => this.handleToggleCreateModal()}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard addClass={'relative'}>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={resource.isLoading}
                        selects={selects}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        saveTableOptions={this.setOptions}
                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onView={this.handleToggleWoDialog}
                        onEdit={this.handleToggleEditModal}
                        onDelete={this.archiveResource}
                        onRestore={this.restoreFromArchive}

                        hasViewPerm={checkPerm(Resources.WorkOrderScheduledMaintenanceWorkOrders, READ_PERM)}
                        hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}
                        hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}
                        hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                    />

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={translate('title.create_maintenance')}
                    widthClass="max-w-xl"
                    gridClass={"grid gap-4 p-6 grid-rows-10 grid-cols-2"}
                    onClose={this.handleToggleCreateModal}
                    fields={this.state.createFields}
                    canSubmit={this.state.canSubmit}
                    onSubmit={(params) => {
                        if (params) {
                            let vehicleTruckValues = Object.values(this.state.truckAssetFields).map((it, index) => {
                                return FieldsManager.getFieldKeyValues(this.state.truckAssetFields[index])
                            })

                            vehicleTruckValues = vehicleTruckValues.map((it) => {
                                return {
                                    TruckID: it?.TruckID,
                                    LastOdometerRun: it?.LastOdometerRun === '' ? 0 : it.LastOdometerRun,
                                    // LastHoursRun: it?.LastHoursRun === '' ? 0 : it.LastHoursRun,
                                    LastDateRun: it?.LastDateRun
                                }
                            })

                            let vehicleTrailerValues = Object.values(this.state.trailerAssetFields).map((it, index) => {
                                return FieldsManager.getFieldKeyValues(this.state.trailerAssetFields[index])
                            })

                            vehicleTrailerValues = vehicleTrailerValues.map((it) => {
                                return {
                                    TrailerID: it?.TrailerID,
                                    // LastOdometerRun: it?.LastOdometerRun === '' ? 0 : it.LastOdometerRun,
                                    LastHoursRun: it?.LastHoursRun === '' ? 0 : it.LastHoursRun,
                                    LastDateRun: it?.LastDateRun
                                }
                            })

                            const mergeWithNumericKeys = (obj1, obj2)=> {
                                const result = {};

                                let index = 0;

                                Object.values(obj1).forEach(value => {
                                    result[index] = value;
                                    index++;
                                });

                                Object.values(obj2).forEach(value => {
                                    result[index] = value;
                                    index++;
                                });

                                return result;
                            }

                            params.Assets = mergeWithNumericKeys(vehicleTruckValues, vehicleTrailerValues)

                            params.AlertContactIDs = Object.keys(this.state.contactsList).join(',');

                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: this.getQuery(),
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: translate("message.ScheduleMaintenanceCreated"),
                            }))

                            this.handleToggleCreateModal()
                        }
                    }}
                    htmlAfter={
                        <div className={"grid"}>
                            <div className={"mx-9"}>
                                <InfoBar>
                                    {translate("text.alert_upcoming_maintenance")}
                                </InfoBar>
                            </div>

                            <Button onClick={() => this.handleToggleAllContactsModal()}
                                    className={"flex mx-auto my-2 btn-outline"}>
                                Contacts list (choose one or more system users to receive email and notification)
                            </Button>

                            <div className={"flex mx-auto"}>
                                Contacts selected:
                                <span className={"ml-2"}>{Object.values(this.state.contactsList).length}</span>
                            </div>

                            <Card addClass={"m-2"}>
                                <CardTitle title={translate("text.assets")}/>
                                {fieldsAssetsToHtml}
                            </Card>

                            {!!fieldsTruckAssetFields.length && (
                                <div className={"grid justify-items-center text-lg"}>Trucks</div>
                            )}

                            <div className={"grid grid-cols-3 gap-4 mx-4"}>
                                {fieldsTruckAssetFields}
                            </div>

                            {!!fieldsTrailerAssetFields.length && (
                                <div className={"grid justify-items-center text-lg"}>Trailers</div>
                            )}

                            <div className={"grid grid-cols-3 gap-4 mx-4"}>
                                {fieldsTrailerAssetFields}
                            </div>
                        </div>
                    }

                    translate={this.props.translate}
                    handleInputChange={this.handleCreateInputChange}
                    closeButtonLabel={translate('btn.cancel')}
                />

                <ModalSaveResource
                    show={this.state.editModalOpen}
                    title={translate('title.edit_maintenance')}
                    widthClass="max-w-xl"
                    gridClass={"grid gap-4 p-6 grid-rows-10 grid-cols-2"}
                    onClose={this.handleToggleEditModalClose}
                    fields={this.state.createFields}
                    canSubmit={this.state.canSubmit}
                    onSubmit={(params) => {
                        if (params) {
                            let vehicleTruckValues = Object.values(this.state.truckAssetFields).map((it, index) => {
                                return FieldsManager.getFieldKeyValues(this.state.truckAssetFields[index])
                            })

                            vehicleTruckValues = vehicleTruckValues.map((it) => {
                                return {
                                    ScheduledMaintenanceAssetID: it?.ScheduledMaintenanceAssetID,
                                    ScheduledMaintenanceID: it?.ScheduledMaintenanceID,
                                    TruckID: it?.TruckID,
                                    LastOdometerRun: it?.LastOdometerRun === '' ? 0 : it.LastOdometerRun,
                                    // LastHoursRun: it?.LastHoursRun === '' ? 0 : it.LastHoursRun,
                                    LastDateRun: it?.LastDateRun
                                }
                            })

                            let vehicleTrailerValues = Object.values(this.state.trailerAssetFields).map((it, index) => {
                                return FieldsManager.getFieldKeyValues(this.state.trailerAssetFields[index])
                            })

                            vehicleTrailerValues = vehicleTrailerValues.map((it) => {
                                return {
                                    ScheduledMaintenanceAssetID: it?.ScheduledMaintenanceAssetID,
                                    ScheduledMaintenanceID: it?.ScheduledMaintenanceID,
                                    TrailerID: it?.TrailerID,
                                    // LastOdometerRun: it?.LastOdometerRun === '' ? 0 : it.LastOdometerRun,
                                    LastHoursRun: it?.LastHoursRun === '' ? 0 : it.LastHoursRun,
                                    LastDateRun: it?.LastDateRun
                                }
                            })
                            const mergeWithNumericKeys = (obj1, obj2)=> {
                                const result = {};

                                let index = 0;

                                Object.values(obj1).forEach(value => {
                                    result[index] = value;
                                    index++;
                                });

                                Object.values(obj2).forEach(value => {
                                    result[index] = value;
                                    index++;
                                });

                                return result;
                            }

                            params.Assets = mergeWithNumericKeys(vehicleTruckValues, vehicleTrailerValues)

                            params.AlertContactIDs = Object.keys(this.state.contactsList).join(',');

                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                query: this.getQuery(),
                                errorMessage: true, successMessage: translate("message.ScheduleMaintenanceUpdated")
                            }))

                            this.handleToggleEditModalClose()
                        }
                    }}
                    htmlAfter={
                        <div className={"grid"}>
                            <div className={"mx-9"}>
                                <InfoBar>
                                    {translate("text.alert_upcoming_maintenance")}
                                </InfoBar>
                            </div>

                            <Button onClick={() => this.handleToggleAllContactsModal()}
                                    className={"flex mx-auto my-2 btn-outline"}>
                                Contacts list (choose one or more system users to receive email and notification)
                            </Button>


                            {this.state.contactsList !== "" ?
                                (<div className={"flex mx-auto"}>
                                    Contacts selected:
                                    <span className={"ml-2"}>{Object.values(this.state.contactsList).length}</span>
                                </div>) : <></>
                            }

                            <Card addClass={"m-2"}>
                                <CardTitle title={translate("text.assets")}/>
                                {fieldsAssetsToHtml}
                            </Card>

                            {!!fieldsTruckAssetFields.length && (
                                <div className={"grid justify-items-center text-lg"}>Trucks</div>
                            )}

                            <div className={"grid grid-cols-3 gap-4 mx-4"}>
                                {fieldsTruckAssetFields}
                            </div>

                            {!!fieldsTrailerAssetFields.length && (
                                <div className={"grid justify-items-center text-lg"}>Trailers</div>
                            )}

                            <div className={"grid grid-cols-3 gap-4 mx-4"}>
                                {fieldsTrailerAssetFields}
                            </div>
                        </div>
                    }
                    translate={this.props.translate}
                    handleInputChange={this.handleCreateInputChange}
                    closeButtonLabel={translate('btn.cancel')}
                />

                <ResourceTableDialog
                    show={this.state.isAllContactsModalOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.ContactsQuick}
                    title={translate("btn.add_contacts")}
                    dispatch={this.props.dispatch}

                    fields={{
                        FirstName: new Field('FirstName', '', [''], false),
                        LastName: new Field('LastName', '', [''], false),
                        Email: new Field('Email', '', [''], false)
                    }}

                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {addContainerClass: 'col-span-3'}),
                        ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search', {addContainerClass: 'col-span-3'}, {
                            isClearable: true,
                            api: 'api/' + Resources.ContactGroupsQuick,
                            query: {},
                            searchMap: (it) => ({
                                label: it.ContactGroupName,
                                value: it.ContactGroupID,
                                metadata: it
                            })
                        }),
                    }}

                    defaultQuery={{IsSystemUser: 1}}
                    defaultAction={this.handleSelectContact}

                    tableKey={"ContactID"}
                    onSelectRow={this.handleSelectContact}
                    onSelectAllClick={this.handleSelectAllContacts}
                    selectedRows={this.state.selectedContactsToAdd}

                    buttonLabel={translate('btn.confirm_selection')}
                    onButtonClick={this.handleAddContacts}
                    buttonDisabled={false}

                    onClose={() => this.setState({isAllContactsModalOpen: false})}
                    translate={translate}
                />

                <ModalDefault
                    title={translate("title.related_work_orders")}
                    show={this.state.showWoDialog}
                    onClose={this.handleToggleWoDialog}
                    translate={translate}
                    widthClass={"max-w-7xl"}
                >

                    {(isSecondLoading || !!secondCount) && (
                        <ResourceTable
                            data={secondData}
                            fields={this.getWoFields()}
                            queryFields={this.state.queryWoFields}

                            options={{
                                style: {
                                    stripedRows: true,
                                    horizontalLines: true,
                                    verticalLines: true,
                                    floatingActions: true
                                }
                            }}


                            translate={translate}
                            isLoading={isSecondLoading}
                        />
                    )}

                    <NoRecordsTable
                        show={(secondData.length === 0) && !isSecondLoading}
                        canCreate={false}
                        title={'No matching records found'}
                        className={"pb-12 pt-16 px-6"}
                    />

                    <TableCardFooter
                        show={!(!secondData.length && !isSecondLoading)}
                    >
                        <Pagination
                            count={secondCount}
                            isLoading={isSecondLoading}
                            handleQueryChange={this.handleFilterWoInputChange}
                            queryFields={this.state.queryWoFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </ModalDefault>

                <ResourceTableDialog
                    show={this.state.isTruckModalOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.TrucksQuick}
                    title={translate("text.select_truck")}
                    dispatch={this.props.dispatch}
                    tableKey={"TruckID"}
                    fields={this.getTruckFields()}
                    widthClass={"max-w-7xl"}
                    options={contactTableOptions}

                    fieldsFilter={
                        Object.assign(this.getAssetFilters(),
                            // {NotSoldRetired: new Field('NotSoldRetired', '1', [''], false, "hidden")}
                            {}
                        )}

                    searchFields={
                        {
                            OfficeID: new Field('OfficeID', '', [''], false, 'select-search',{
                                addContainerClass: 'col-span-3',
                                labelType: "float"
                            }, {
                                isClearable: true
                            }),
                            ContactGroupID: new Field('ContactGroupID', '', [], false, 'select-search', {
                                addContainerClass: 'col-span-3',
                                labelType: "float"
                            }, {
                                isClearable: true
                            })
                        }
                    }
                    sortBy={"TruckNumber"}
                    defaultAction={(item) => this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID))}
                    onSelectRow={this.handleSelectRowClickTruck}
                    selectedRows={this.state.selectedTrucksRows}
                    onSelectAllClick={this.handleSelectAllClickTruck}
                    selects={getAssetSelects}
                    onClose={this.toggleTruckSelectDialog}
                    buttonLabel={translate("btn.confirm_selection")}
                    onButtonClick={() => {
                        this.toggleTruckSelectDialog();
                        let TrailersList = this.state.assetFields.TrailerIDs.value;

                        let result = TrailersList?.length ? TrailersList.reduce((acc, num) => {
                            acc[num.value] = num.metadata
                            return acc;
                        }, {}) : {}

                        let vehicleFields = Object.values(this.state.selectedTrucksRows).map((it) => {
                            return this.getVehicleFields(it)
                        })


                        this.setState({
                            assetFields: this.getCreateAssetFields(this.state.selectedTrucksRows, result)
                        })


                        const addElementsToState = (newElements) => {
                            this.setState((prevState) => {
                                const filteredArray = prevState.truckAssetFields.filter(
                                    (existingElement) => newElements.some((newElement) => newElement.TruckID.value === existingElement.TruckID.value)
                                );

                                const newUniqueElements = newElements.filter(
                                    (newElement) => !prevState.truckAssetFields.some((existingElement) => existingElement.TruckID.value === newElement.TruckID.value)
                                );

                                return {
                                    truckAssetFields: [...filteredArray, ...newUniqueElements],
                                };
                            });
                        };

                        addElementsToState(vehicleFields)


                    }}
                    translate={translate}
                />

                <ResourceTableDialog
                    show={this.state.isTrailerModalOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.TrailersQuick}
                    title={translate("text.select_trailer")}
                    dispatch={this.props.dispatch}
                    tableKey={"TrailerID"}
                    fields={this.getTrailerFields()}
                    widthClass={"max-w-7xl"}
                    options={contactTableOptions}

                    fieldsFilter={
                        Object.assign({},this.getAssetFilters(),
                            // {NotSoldRetired: new Field('NotSoldRetired', '1', [''], false, "hidden")}
                            {}
                        )}

                    searchFields={
                        {
                            OfficeID: new Field('OfficeID', '', [''], false, 'select-search',{
                                addContainerClass: 'col-span-3',
                                labelType: "float"
                            }, {
                                isClearable: true
                            }),
                            ContactGroupID: new Field('ContactGroupID', '', [], false, 'select-search', {
                                addContainerClass: 'col-span-3',
                                labelType: "float"
                            }, {
                                isClearable: true
                            })
                        }
                    }
                    sortBy={"TrailerNumber"}
                    defaultAction={(item) => this.props.dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID))}
                    onSelectRow={this.handleSelectRowClickTrailer}
                    selectedRows={this.state.selectedTrailerRows}
                    onSelectAllClick={this.handleSelectAllClickTrailer}
                    selects={getAssetSelects}
                    onClose={this.toggleTrailerSelectDialog}
                    buttonLabel={translate("btn.confirm_selection")}
                    onButtonClick={() => {
                        this.toggleTrailerSelectDialog();
                        let TrucksList = this.state.assetFields.TruckIDs.value;

                        let result = TrucksList?.length ? TrucksList.reduce((acc, num) => {
                            acc[num.value] = num.metadata
                            return acc;
                        }, {}) : {}

                        let vehicleFields = Object.values(this.state.selectedTrailerRows).map((it) => {
                            return this.getVehicleFields(it)
                        })


                        this.setState({
                            assetFields: this.getCreateAssetFields(result, this.state.selectedTrailerRows)
                        })

                        const addElementsToState = (newElements) => {
                            this.setState((prevState) => {
                                const filteredArray = prevState.trailerAssetFields.filter(
                                    (existingElement) => newElements.some((newElement) => newElement.TrailerID.value === existingElement.TrailerID.value)
                                );

                                const newUniqueElements = newElements.filter(
                                    (newElement) => !prevState.trailerAssetFields.some((existingElement) => existingElement.TrailerID.value === newElement.TrailerID.value)
                                );

                                return {
                                    trailerAssetFields: [...filteredArray, ...newUniqueElements],
                                };
                            });
                        };

                        addElementsToState(vehicleFields)
                    }}
                    translate={translate}
                />
            </React.Fragment>
        )
    }
}

export default connect(state => state)(ScheduledMaintenanceTab)
