import React, {useEffect, useState} from "react";
import Layout from "../../../common/components/layout";
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import {getDefaultQueryFields, getUser} from "../../../util/util";
import {useDispatch, useSelector} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../../data/actions/resource";
import useQuery from "../../../hooks/use-query";
import {checkPerm, getProp} from "../../../common/util/util-helpers";
import LocalStorage from "../../../util/localStorage";
import ResourceList from "../../../common/components/layout/layout-components/resource-list";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Page from "../../../common/components/layout/layout-components/page";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY, DELETE_PERM, UPDATE_PERM} from "../../../common/util/util-consts";
import {DEFAULT_LIMIT, DEFAULT_OFFSET, DEFAULT_SORT} from "../../../util/util-constants";
import useQueryNew from "../../../hooks/use-query-new";

export default function WorkOrderTemplatesView({history, match, translate}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = getProp(resource, "isLoading", true);

    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useQueryNew(getQueryFields(translate), getListPath(), true);

    const query = FieldsManager.getFieldKeyValues(queryFields);

    const [isResourceDialogOpen, setIsResourceDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [confirmDialogData, setConfirmDialogData] = useState({});

    /** UI events
     ================================================================= */

    useEffect(() => {
        fetchData(dispatch, query);
    }, [queryFields]);

    const handleEditResourceClick = (item) => {
        history.push('/work-orders-templates/' + item.WorkOrderTemplateID);
    }

    const handleCreateResourceClick = () => {
        history.push('/work-orders-templates-create');
    }

    const handleDeleteTemplateClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeleteTemplateClick(item),
            type: "danger",
            content: <span>
                Are you sure you want to delete this template?
            </span>,
            title: translate("text.confirm_delete_template"),
            buttonLabel: translate("btn.delete")
        })
    }

    const handleConfirmDeleteTemplateClick = (item) => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign({},{WorkOrderTemplateID: item.WorkOrderTemplateID}),
            notificationMessage: translate(`text.wo_template_deleted`),
            resource: getResourcePath(),
            piggyResource: getResourcePath(),
            successMessage: translate("message.wo_template_delete"),
            errorMessage: true
        }));

        closeConfirmDialog();
    }

    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <Layout
                history={history}
                match={match}
                translate={translate}
            >
                <Page>
                    <PageHeader
                        title={translate("page.title.work-orders-templates")}
                        buttons={[
                            {
                                label: translate("btn.create"),
                                type: "primary",
                                onClick: handleCreateResourceClick
                            }
                        ]}
                    />

                    <ResourceList
                        fields={getFields({})}

                        resource={resource}
                        isLoading={isLoading}
                        queryFields={queryFields}
                        setQueryFields={setQueryFields}
                        listPath={getListPath()}
                        onRefreshTable={() => fetchData(dispatch, query)}
                        dispatch={dispatch}
                        query={query}

                        translate={translate}
                        selects={getSelects()}

                        onRowClick={handleEditResourceClick}
                        onEdit={handleEditResourceClick}
                        onDelete={handleDeleteTemplateClick}

                        hasDeletePerm={checkPerm(getResourcePath(), DELETE_PERM)}
                        hasEditPerm={checkPerm(getResourcePath(), UPDATE_PERM)}

                    />

                    <ModalConfirm
                        show={confirmDialogData?.isOpen ?? false}
                        type={confirmDialogData?.type}
                        title={confirmDialogData?.title}
                        onConfirm={confirmDialogData?.onClick}
                        closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                        buttonLabel={confirmDialogData?.buttonLabel}
                        translate={translate}
                        onClose={closeConfirmDialog}
                    >
                        {confirmDialogData?.content}
                    </ModalConfirm>
                </Page>
            </Layout>
        </React.Fragment>
    )
}

/** Page methods
 ================================================================= */
const getPrimaryField = () => "WorkOrderTemplateID";
const getResourcePath = () => Resources.WorkOrderTemplates;

const getListPath = () => getResourcePath() + "_list"

const fetchData = (dispatch, query) => {
    dispatch(getResource({
        user: getUser(),
        resource: getResourcePath(),
        query: query,

    }))

}

const getQueryFields = (translate) => {
    return (
        {
            query: new Field('query', '', [''], false, 'search', {
                hideLabel: true,
                hasActiveBadge: true,
                labelType: "float"
            }, {placeholder: translate("text.search")}),
            sort: new Field('sort', DEFAULT_SORT, [''], false, 'hidden'),
            sortBy: new Field('sortBy', getPrimaryField(), [''], false, 'hidden'),
            offset: new Field('offset', DEFAULT_OFFSET, [''], false, 'hidden'),
            limit: new Field('limit', DEFAULT_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {
                hasPortal: true,
                placeholder: "100",
                menuPlacement: "top"
            }),
            // BayType: new Field('BayType', '', [''], false, 'select', {
            //     hasActiveBadge: true,
            // }, {isClearable: true}),
            // PrimaryTechnicianID: new Field('PrimaryTechnicianID', '', [''], false, 'select-search', {
            //     hasActiveBadge: true,
            // },{isClearable: true})
        }
    )
}

export function getFields(item = {}) {
    const fieldTemplates = {
        Description: new Field('Description', '', ['empty'], false, 'textarea', {addContainerClass: 'col-span-full'}),
        Notes: new Field('Notes', '', [''], false, "textarea", {addContainerClass: "col-span-full"}),
        LaborAmount: new Field('LaborAmount', '', [''], false, "float", {addContainerClass: "col-span-full"}),
        PartsAmount: new Field('PartsAmount', '', [''], false, "float", {addContainerClass: "col-span-full"}),
        MiscAmount: new Field('MiscAmount', '', [''], false, "float", {addContainerClass: "col-span-full"}),
        TotalAmount: new Field('TotalAmount', '', [''], false, "float", {addContainerClass: "col-span-full"}),
    }

    return fillFieldsFromData(fieldTemplates, item)
}


export function getSelects() {
    return {
        // PrimaryTechnicianID: {
        //     api: 'api/' + Resources.ContactsQuick,
        //     query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        //     searchMap: (item) => ({
        //         value: item.ContactID,
        //         label: item.FirstName + " " + item.LastName
        //     })
        // }
    }
}
