import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {showModal} from '../../../data/actions/ui'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    resourceIsCreated,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {download} from '../../../data/actions/download'
import {
    ArrowUturnLeftIcon,
    CheckIcon,
    EyeIcon,
    EyeSlashIcon,
    KeyIcon,
    LockClosedIcon,
    TrashIcon
} from '@heroicons/react/24/outline'
import Page from "../../../common/components/layout/layout-components/page";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM, READ_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {getContactResource} from '../../../data/actions/contactResource'
import ClipboardDocumentIcon from '@heroicons/react/24/outline/ClipboardDocumentIcon'
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import {createDialogResource} from "../../../data/actions/dialogResource";
import ContactForm from "../../../common/components/forms/contact-form";
import Layout from "../../../common/components/layout";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Modal from "../../../common/components/modal";
import ModalDefault from "../../../common/components/modal/modal-default";

class UsersView extends Component {

    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'FirstName',
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            tableColumns: {},
            prefilled: '',

            selectedItem: null,
            createUpdateDialog: false,
            confirmDialog: false,
            showResetPasswordDialog: false,
            resetPasswordDialog: false,
            contactCreatedMessage: false,
            activateModalOpen: false,
            breakpoint: {},
            isTableFullScreen: false
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour);
        }

        this.fileImageObject = null
        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.resource.errorMessage && !prevProps.resource.errorMessage && (this.props.resource.errorMessage === 'EMAIL_PARAMETER_ALREADY_EXISTS')) {
            this.setState({emailExist: true})
        }

        if (resourceIsCreated(this.props.resource, prevProps.resource)) {
            this.setState({
                createUpdateDialog: false
            })
        }

        if (resourceIsCreated(this.props.dialogResource, prevProps.dialogResource) && !!this.props.dialogResource.create.password) {
            this.setState({
                temporaryPassword: this.props.dialogResource.create.password,
                temporaryPasswordModal: true
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.SystemContacts,
            query: this.getQuery()
        }))
    }

    fetchContactData = () => {
        if (!this.state.selectedItem) {
            return false
        }

        this.props.dispatch(getContactResource({
            user: LocalStorage.get('user'),
            resource: Resources.Contacts,
            query: {id: this.state.selectedItem.ContactID}
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'contacts_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'contacts_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    archiveResource = (item) => {
        const isMe = !!(LocalStorage.get('user')?.Contact.ContactID === item.ContactID)
        if (!isMe) {
            this.setState({text: `${this.props.translate('message.confirm_deactivate_user', [`${item.FirstName} ${item.LastName}`])}`}, () => {
                this.handleToggleConfirmDialog(() => {
                    this.setState({
                        ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                        confirmDialog: false
                    }, () => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get('user'),
                            query: Object.assign({}, this.getQuery(), {ContactID: item.ContactID}),
                            errorMessage: true,
                            successMessage: this.props.translate(`message.contact_deactivated`, [item.FirstName, item.LastName]),
                            resource: this.getResource(),
                            piggyResource: Resources.SystemContacts
                        }))
                    })
                }, item)
            })
        } else {
            return null
        }
    }

    restoreFromArchive = (item) => {
        this.setState({text: `${this.props.translate('message.are_you_sure_restore_user', [`${item.FirstName} ${item.LastName}`])}`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item.ContactID,
                            ArchivedDate: 1
                        },
                        errorMessage: true, successMessage: `User ${item.FirstName} ${item.LastName} reactivated.`,
                        query: this.getQuery(),
                        resource: this.getResource(),
                        piggyResource: Resources.SystemContacts
                    }))
                })
            }, item)
        })
    }
    activateUser = (item) => {
        this.toggleActivateModal(() => {
            this.setState({activateModalOpen: false}, () => {
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: {
                        ContactID: item.ContactID
                    },
                    errorMessage: true, successMessage: `User ${item.FirstName} ${item.LastName} activated.`,
                    query: this.getQuery(),
                    resource: Resources.ContactsActivate,
                    piggyResource: Resources.SystemContacts
                }))
            })
        })
    }
    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleShowDetails = (item) => {
        this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}));
    }

    handleActionPermissions = (item) => {
        this.props.history.push(`/contacts/${item.ContactID}`);
    }

    toggleActionResendPasswordDialog = (item = null) => {
        this.setState({
            resetPasswordDialog: !this.state.resetPasswordDialog,
            selectedItem: item
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'FirstName'
            sort = 'ASC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }
    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleCreateUpdateResource = (item = null) => {
        this.setState({selectedItem: item, createUpdateDialog: true})
    }

    handleCloseCreateUpdateResource = () => {
        this.setState({
            createUpdateDialog: false,
            emailExist: null,
            createdPassword: null,
        })
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    toggleActivateModal = (submit) => {
        this.setState({
            activateModal: submit,
            activateModalOpen: !this.state.activateModalOpen
        })
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return 'Email'
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            paginationPage: this.state.paginationPage,
            IsSystemUser: 1,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}),
            Groups: new Field('Groups', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            }),
            HighImportance: new Field('HighImportance', '', [''], false, 'checkbox'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
        }
    }

    getFields = () => {
        return {
            FirstName: new Field('FirstName', '', ['empty']),
            LastName: new Field('LastName', '', ['empty']),
            Nickname: new Field('Nickname', '', ['empty']),
            Email: new Field('Email', '', ['empty', 'email']),
            PrivateEmail: new Field('PrivateEmail', '', ['empty', 'email']),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'date'),
            IsActivatedSystemUser: new Field('IsActivatedSystemUser', '', [], false, 'checkbox')
        }
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    copyToClipboard = (password) => {
        this.setState({
            urlCopied: true
        }, () => {
            navigator.clipboard.writeText(password)
        })

        setTimeout(() => {
            this.setState({urlCopied: false})
        }, 2000)
    }

    getResource = () => {
        return Resources.SystemContacts
    }

    handleClearErrorData = () => {
        this.setState({
            emailExist: false
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, contactResource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const contactData = getProp(contactResource, 'data', null)
        const contactIsLoading = getProp(contactResource, 'isLoading', false)
        const isLoading = resource.isLoading

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.SystemUsers')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateUpdateResource()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            {/*<Tippy content={translate('text.download_template')}>*/}
                            {/*    <button*/}
                            {/*        className="btn-icon"*/}
                            {/*        onClick={this.downloadExcelTemplate}*/}
                            {/*    >*/}
                            {/*        <RectangleGroupIcon className="w-5 h-5"/>*/}
                            {/*    </button>*/}
                            {/*</Tippy>*/}

                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard
                        addClass={
                            classNames(
                                this.state.isTableFullScreen
                                    ? "fixed inset-1 -top-1 z-50"
                                    : undefined
                            )}
                    >
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            onFullScreenToggle={this.handleFullScreenToggle}
                            isTableFullScreen={this.state.isTableFullScreen}
                            isLoading={isLoading}
                        />

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new contact'}
                            btnLabel="Create contact"
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                    this.handleCreateUpdateResource()
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}

                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleCreateUpdateResource : null}

                            onView={this.handleShowDetails}
                            onEdit={this.handleCreateUpdateResource}

                            hasViewPerm={checkPerm(this.getResource(), READ_PERM)}
                            hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}

                            tableKey={"UserID"}

                            actions={[
                                {
                                    action: this.archiveResource,
                                    icon: TrashIcon,
                                    visible: (it) => (!it.ArchivedDate && getProp(LocalStorage.get("user"), 'Contact.ContactID', 0) !== it.ContactID),
                                    hasPerm: checkPerm(this.getResource(), DELETE_PERM),
                                    label: false,
                                    title: translate('btn.deactivate'),
                                    order: 100
                                },
                                {
                                    action: this.restoreFromArchive,
                                    icon: ArrowUturnLeftIcon,
                                    visible: (it) => !!it.ArchivedDate,
                                    hasPerm: checkPerm(this.getResource(), UPDATE_PERM),
                                    label: false,
                                    title: translate('btn.reactivate'),
                                    order: 61
                                },
                                {
                                    action: this.handleActionPermissions,
                                    icon: KeyIcon,
                                    hasPerm: () => checkPerm(Resources.UserPermissions, READ_PERM),
                                    label: false,
                                    title: translate('text.permissions'),
                                    order: 62
                                },
                                {
                                    action: this.toggleActionResendPasswordDialog,
                                    icon: LockClosedIcon,
                                    hasPerm: () => checkPerm(Resources.SystemContactsPassword, CREATE_PERM),
                                    label: false,
                                    title: translate('text.ResendPassword'),
                                    order: 63
                                },
                                {
                                    action: this.activateUser,
                                    icon: CheckIcon,
                                    visible: (it) => !it.IsActivatedSystemUser,
                                    hasPerm: checkPerm(this.getResource(), UPDATE_PERM),
                                    label: false,
                                    title: translate('btn.Activate'),
                                    order: 64
                                },
                            ]}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    </TableCard>

                    <Modal
                        show={this.state.createUpdateDialog}
                        widthClass={'max-w-3xl'}
                        onClose={this.handleCloseCreateUpdateResource}
                    >
                        <ContactForm
                            forceSystemUser={true}
                            data={!!this.state.selectedItem && contactData}
                            isLoading={contactIsLoading}
                            onMount={this.fetchContactData}
                            onClose={this.handleCloseCreateUpdateResource}
                            errorMessages={this.state.emailExist ? [{
                                field: 'Email',
                                message: 'text.email_exists'
                            }] : []}
                            clearErrorData={this.handleClearErrorData}
                            onSubmit={(params, file, documents = []) => {
                                if (this.state.selectedItem) {
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        params: params,
                                        errorMessage: true,
                                        successMessage: this.props.translate('message.ContactUpdatedSuccessfully'),
                                        query: this.getQuery(),
                                        resource: this.getResource(),
                                        piggyResource: Resources.SystemContacts,
                                        file: !!file && [file],
                                        fileResource: Resources.SystemContactImage,
                                        document: documents,
                                        documentResource: Resources.SystemContactsDocuments
                                    }))

                                    this.handleCloseCreateUpdateResource()
                                } else {
                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get('user'),
                                        params: params,
                                        errorMessage: true,
                                        successMessage: this.props.translate('message.ContactCreatedSuccessfully'),
                                        query: this.getQuery(),
                                        resource: this.getResource(),
                                        piggyResource: Resources.SystemContacts,
                                        file: !!file && [file],
                                        fileResource: Resources.SystemContactImage,
                                        document: documents,
                                        documentResource: Resources.SystemContactsDocuments
                                    }))
                                    this.handleCloseCreateUpdateResource()
                                }
                            }}
                            translate={translate}
                            isSystemUser={true}
                        />
                    </Modal>

                    <ModalDefault
                        show={!!this.state.temporaryPasswordModal}
                        widthClass={'max-w-xl'}
                        title={'Contact Created Successfully'}
                        closeButtonLabel={translate('btn.cancel')}
                        onClose={() => this.setState({temporaryPasswordModal: false})}
                    >
                        <div className="p-5">
                            <button
                                className={classNames(!this.state.urlCopied ? 'text-gray-900 hover:bg-gray-100' : 'text-green-900 hover:bg-green-100', 'w-full p-3 flex items-center rounded-md text-base font-medium transition ease-in-out duration-150')}
                                onClick={() => {
                                    this.copyToClipboard(this.state.temporaryPassword)
                                }}
                            >
                                <ClipboardDocumentIcon className="h-5 w-5"/>
                                <span
                                    className="ml-3">{!this.state.urlCopied ? 'Copy password to clipboard' : 'Password copied to clipboard!'}</span>
                            </button>
                            <div
                                className={classNames('text-gray-900 hover:bg-gray-100', 'w-full p-3 flex items-center rounded-md text-base font-medium transition ease-in-out duration-150')}
                                onMouseEnter={() => {
                                    this.setState({temporaryPasswordVisible: true})
                                }}
                                onMouseLeave={() => {
                                    this.setState({temporaryPasswordVisible: false})
                                }}
                            >
                                {this.state.temporaryPasswordVisible ?
                                    <EyeIcon className="h-5 w-5"/>
                                    :
                                    <EyeSlashIcon className="h-5 w-5"/>
                                }

                                <span
                                    className="ml-3">{this.state.temporaryPasswordVisible ? this.state.temporaryPassword : '****************'}</span>
                            </div>
                        </div>
                    </ModalDefault>

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.setTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    <ModalDefault
                        show={!!this.state.resetPasswordDialog}
                        widthClass="max-w-md"
                        title={`${this.state.selectedItem?.FirstName} ${this.state.selectedItem?.LastName} Resend Password`}
                        closeButtonLabel={translate('btn.cancel')}
                        onClose={this.toggleActionResendPasswordDialog}
                    >
                        <div className={"flex flex-col items-center justify-center w-64 mx-auto px-6 space-y-4 py-8"}>

                            <button className="btn btn-outline-secondary w-full justify-center" onClick={() => {
                                let params = {}
                                if (params) {

                                    params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()]
                                    params.ValidationType = 1
                                    this.props.dispatch(createDialogResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: Resources.SystemContactsPassword,
                                        piggyResource: Resources.SystemContacts,
                                        errorMessage: true,
                                        successMessage: Number(params.ValidationType) === 1
                                            ? translate("text.email_instructions_sent", [this.state.selectedItem?.Email])
                                            : null
                                    }))
                                    this.toggleActionResendPasswordDialog()
                                }
                            }}>Email
                            </button>

                            <button className="btn btn-outline-secondary w-full justify-center" onClick={() => {
                                let params = {}
                                if (params) {

                                    params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()]
                                    params.ValidationType = 3
                                    this.props.dispatch(createDialogResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: Resources.SystemContactsPassword,
                                        piggyResource: Resources.SystemContacts,
                                        errorMessage: true,
                                        successMessage: Number(params.ValidationType) === 3
                                            ? translate("text.temporary_password_created")
                                            : null
                                    }))
                                    this.toggleActionResendPasswordDialog()
                                }
                            }}>Temporary Password
                            </button>
                        </div>
                    </ModalDefault>

                    <ModalConfirm
                        type={'info'}
                        title={'Confirm activation'}
                        show={!!(this.state.activateModalOpen)}
                        text={translate("text.activateAndSendEmail")}
                        width={'2xl'}
                        onClose={this.toggleActivateModal}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.activateModal}
                    />

                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(UsersView)
