import ModalDefault from "../../../../common/components/modal/modal-default";
import React, {useEffect, useState} from "react";
import FieldsForm from "../../../../common/components/fields/fields-form";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {ConstantTranslate} from "../../../../common/util/util-helpers";
import {INVOICE_FACTOR_STATUS_OPTIONS} from "../../../../util/util-constants";
import {updateResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {useDispatch, useSelector} from "react-redux";
import {LoaderLarge} from "../../../../common/components/loader";

export default function SetFactoringModal({show, onClose, query, selectedItem, translate}) {
    const dispatch = useDispatch();
    const InvoiceFactorStatusOptions = ConstantTranslate(INVOICE_FACTOR_STATUS_OPTIONS, translate);

    const resource = useSelector(state => state.resource);
    const isLoading = resource.isLoading;
    const [fields, setFields] = useState(() => getFields());

    function submit() {
        const validatedFields = FieldsManager.validateFields(fields);
        if (!FieldsManager.checkFieldsForErrors(validatedFields)) {
            setFields(validatedFields);
            return null;
        }

        let params = FieldsManager.getFieldKeyValues(fields);
        params = Object.assign({InvoiceID: selectedItem?.InvoiceID}, params);

        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: params,
            resource: Resources.InvoicesFactor,
            piggyResource: Resources.Invoices,
            query: query,
            errorMessage: true,
            successMessage: 'Entry Updated'
        }));
    }

    function handleInputChange(name, value) {
        const fieldsUpdate = FieldsManager.updateField(fields, name, value);
        fieldsUpdate[name].errorMessage = "";
        setFields(fieldsUpdate);
    }

    function getFields() {
        return {
            AmountTransferredFactoring: new Field('AmountTransferredFactoring', selectedItem?.AmountTransferredFactoring ?? "", ['required', 'gt:0'], false, 'money', {
                label: "payedAmount",
                addContainerClass: "col-span-full",
            }),
            InvoiceFactorStatusID: new Field('InvoiceFactorStatusID', selectedItem?.InvoiceFactorStatusID ?? "", ['required'], false, 'select', {
                addContainerClass: "col-span-full",
            }),
            InvoiceFactorStatusDate: new Field('InvoiceFactorStatusDate', selectedItem?.InvoiceFactorStatusDate ?? "", [''], false, 'date', {
                addContainerClass: "col-span-6",
                label: "Date"
            })
        }
    }

    useEffect(() => {
        if (resource?.updated === 1 && show) {
            onClose();
        }
    }, [resource]);

    useEffect(() => {
        if (show) {
            setFields(getFields());
        }
    }, [show]);

    return <ModalDefault
        show={!!show}
        widthClass={'max-w-sm'}
        title={translate('text.set_factoring_balance')}
        buttonLabel={translate("btn.save")}
        onButtonClick={submit}

        closeButtonLabel={translate('btn.Close')}
        onClose={onClose}
    >
        <div className="p-6 grid grid-cols-12 gap-y-4 relative">
            <FieldsForm
                fieldsState={fields}
                isLoading={isLoading}
                onChange={handleInputChange}
                selects={{
                    InvoiceFactorStatusID: InvoiceFactorStatusOptions
                }}
                translate={translate}
            />
            
            {isLoading && (
                <LoaderLarge/>
            )}
        </div>
    </ModalDefault>
}