import ActiveFilters from "../../../../../common/components/resource-table/table-components/active-filters";
import TableFilters from "../../../../../common/components/resource-table/table-components/table-filters";
import PopoverDatePicker from "../../../../../common/components/popover/popover-datepicker";
import ResourceTable from "../../../../../common/components/resource-table";
import {checkPerm, classNames, getDefaultTableOptions} from "../../../../../common/util/util-helpers";
import Resources from "../../../../../data/services/resources";
import {ArrowDownTrayIcon, EyeIcon} from "@heroicons/react/24/outline";
import NoRecordsTable from "../../../../../common/components/no-records-found/no-records-table";
import TableCardFooter from "../../../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../../../common/components/resource-table/table-components/pagination";
import TableBulkActions from "../../../../../common/components/resource-table/table-components/table-bulk-actions";
import Tippy from "@tippyjs/react";
import React, {useEffect, useState} from "react";
import {Field} from "../../../../../data/services/fields";
import {useDispatch, useSelector} from "react-redux";
import {fillFieldsFromData, includeFields} from "../../../../../common/util/util-fields";
import {download} from "../../../../../data/actions/download";
import LocalStorage from "../../../../../util/localStorage";
import {currentDate} from "../../../../../common/util/util-dates";
import {getResource, updateResource} from "../../../../../data/actions/resource";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import Subtitle from "../../../../../common/components/layout/layout-components/page/subtitle";
import ModalDefault from "../../../../../common/components/modal/modal-default";
import FileViewer from "../../../../../common/components/file-viewer/components";
import Env from "../../../../../util/env";
import {getJWT} from "../../../../../common/util/util-auth";
import {READ_PERM, UPDATE_PERM} from "../../../../../common/util/util-consts";
import ModalSaveResource from "../../../../../common/components/modal/modal-save-resource";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../../../util/util-constants";

export default function TruckPayrollSettlement({
                                                   translate,
                                                   query,
                                                   handleQueryInputChange,
                                                   handleQueryClear,
                                                   queryFields,
                                                   location
                                               }) {
    const resourcePath = Resources.TrucksPayroll;

    const dispatch = useDispatch();
    const pagePath = location.pathname.substring(1) + '_payroll';

    const [isSectionExpanded, setIsSectionExpanded] = useState(true);
    const [selectedRows, setSelectedRows] = useState({});
    const [tableOptions, setTableOptions] = useState(getDefaultTableOptions(getFields(), {}, pagePath, translate),);
    const [fields, setFields] = useState(() => getFields());
    const [selectedItem, setSelectedItem] = useState({});
    const [isPayrollViewDialogOpen, setIsPayrollViewDialogOpen] = useState(false);
    const [isPayrollEditDialogOpen, setIsPayrollEditDialogOpen] = useState(false);

    const resource = useSelector(state => state.resource);
    const data = resource?.data?.list ?? [];
    const isLoading = resource.isLoading;
    const count = resource?.data?.count ?? 0;

    const metadata = {
        OfficeID: {
            api: 'api/' + Resources.OfficesQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.OfficeName,
                value: item.OfficeID
            })
        },
        ContactGroupID: {
            api: 'api/' + Resources.ContactGroupsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.ContactGroupName,
                value: item.ContactGroupID
            })
        },
        AccountID: {
            api: 'api/' + Resources.AccountsQuick,
            query: {},
            searchMap: (it) => ({
                label: it.AccountNumber + ' ' + it.AccountName,
                value: it.AccountID
            })
        }
    }

    /** DATA Events
     ================================================================= */
    function fetchData() {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            query: query,
            resource: resourcePath
        }))
    }

    function downloadDocumentBulk(item, name) {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.PayrollBatchesItemsDocuments,
            query: {
                PayrollBatchEntryIDs: item,
                format: 'ZIP',
                name: 'Payroll_statements_' + name + '_' + currentDate() + '.zip'
            },
            errorMessage: true,
            successMessage: 'Successfully downloaded payroll batches!'
        }))
    }

    function downloadDocument(item) {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.PayrollBatchesItemsDocument,
            query: {
                PayrollBatchEntryID: selectedItem?.PayrollBatchEntryID ?? item.PayrollBatchEntryID,
                type: 1,
                name: (selectedItem?.Name ?? item.Name) + ' ' + (selectedItem?.PayrollBatch ?? item.PayrollBatch) + '.pdf'
            },
            errorMessage: true,
            successMessage: 'Successfully downloaded payroll batch!'
        }))
    }

    /** UI Events
     ================================================================= */
    function handleToggleViewModal(selectedItem = {}) {
        setSelectedItem(selectedItem);
        setIsPayrollViewDialogOpen(!isPayrollViewDialogOpen);
    }

    function handleToggleEditModal(selectedItem = {}) {
        setSelectedItem(selectedItem);
        setIsPayrollEditDialogOpen(!isPayrollEditDialogOpen);
    }

    function handleSelectRowClick(item) {
        const itemID = item.PayrollBatchEntryID

        let selectedRowsUpdate = Object.assign({}, selectedRows);

        if (selectedRowsUpdate[itemID]) {
            delete selectedRowsUpdate[itemID]
        } else {
            Object.assign(selectedRowsUpdate, {[itemID]: item})
        }

        setSelectedRows(selectedRowsUpdate);
    }

    function handleSelectAllClick(selectAll) {
        let selectedRowsUpdate = Object.assign({}, selectedRows);

        if (!selectAll) {
            Object.assign(selectedRowsUpdate, data.reduce((memo, it) => {
                memo[it.PayrollBatchEntryID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRowsUpdate)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.PayrollBatchEntryID.toString())) {
                    delete selectedRowsUpdate[it.PayrollBatchEntryID]
                }
            })
        }

        setSelectedRows(selectedRowsUpdate);
    }

    /** Helpers
     ================================================================= */


    function getFields(item = null) {
        const fieldTemplates = {
            PayrollBatch: new Field('PayrollBatch', '', [], false, 'text'),
            Date: new Field('Date', '', [], false, 'date'),
            BookDate: new Field('BookDate', '', [''], false, 'date', {
                label: 'CheckPostingDate'
            }),
            Gross: new Field('Gross', '', [], false, 'money'),
            TotalAmount: new Field('TotalAmount', '', [], false, 'money', {
                label: 'NetAmount'
            }),
            PayToOrganizationID: new Field('PayToOrganizationID', '', [], false, 'custom', {
                render: (it) => {
                    return it.PayToOrganizationID ? it.PayToOrganization : ''
                }
            }),
            IncludeIn1099: new Field('IncludeIn1099', '', [], false, 'checkbox', {addContainerClass: "col-span-full"}),
            IsPayed: new Field('IsPayed', '', [], false, 'checkbox')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData();
    }, [])

    /** Render
     ================================================================= */
    return (
        <>
            <div className="flex justify-between">
                <div className="flex items-center">
                    <button
                        className="btn btn-icon text-tm-gray-500 hover:text-tm-gray-700 w-8 h-8 -ml-2 md mr-2"
                        onClick={() => setIsSectionExpanded(!isSectionExpanded)}
                    >
                        <ChevronRightIcon
                            className={
                                classNames(
                                    'w-5 h-5',
                                    isSectionExpanded ? 'rotate-90' : undefined
                                )
                            }
                        />
                    </button>

                    <Subtitle subtitle={translate('text.payroll_statements')}/>
                </div>
            </div>

            {isSectionExpanded && (
                <div>
                    <div className={'mb-5'}>
                        <ActiveFilters
                            filterFields={queryFields}
                            onLabelClick={handleQueryInputChange}
                            onClearFiltersClick={handleQueryClear}
                            translate={translate}
                        />
                    </div>

                    <div
                        className={"flex flex-col max-h-full md:bg-inverse md:border border-tm-gray-300 md:shadow-card rounded-card"}>
                        <TableFilters
                            hideLimit
                            filterFields={queryFields}
                            handleInputChange={handleQueryInputChange}
                            customHeaderHtml={
                                <PopoverDatePicker
                                    queryFields={queryFields}
                                    onQueryInputChange={handleQueryInputChange}
                                    updateFields={handleQueryInputChange}
                                    translate={translate}
                                />
                            }
                            translate={translate}
                        />

                        <ResourceTable
                            data={data}
                            count={count}

                            tableKey="PayrollBatchEntryID"
                            onSelectRow={handleSelectRowClick}
                            selectedRows={selectedRows}
                            onSelectAllClick={handleSelectAllClick}
                            options={tableOptions}

                            fields={fields}
                            translate={translate}
                            isLoading={isLoading}

                            onSortChange={handleQueryInputChange}

                            onRowClick={checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM) && downloadDocument}
                            onEdit={checkPerm(Resources.TrucksPayroll, UPDATE_PERM) && handleToggleEditModal}

                            actions={[
                                {
                                    tooltipText: () => translate('btn.preview'),
                                    action: (it) => handleToggleViewModal(it),
                                    icon: () => EyeIcon,
                                    hidden: () => false,
                                    title: () => translate('btn.preview'),
                                    visible: () => checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM),
                                },
                                {
                                    tooltipText: () => translate('btn.download'),
                                    action: (it) => downloadDocument(it),
                                    icon: () => ArrowDownTrayIcon,
                                    hidden: () => false,
                                    title: () => translate('btn.download'),
                                    visible: () => checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM),
                                },
                            ]}
                        />


                        <NoRecordsTable
                            show={(data.length === 0) && !isLoading}
                            canCreate={false}
                            title={translate('text.no_matching_records')}
                            className={'pt-16 px-6'}
                        />
                        <TableCardFooter>
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                handleQueryChange={handleQueryInputChange}
                                queryFields={queryFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <TableBulkActions
                            selectedRows={selectedRows}
                            tableKey={'PayrollBatchEntryID'}
                            fields={getFields()}
                            translate={translate}
                            options={tableOptions}
                            setSelectedRows={setSelectedRows}
                            onSelectAllClick={handleSelectAllClick}
                        >
                            <div className="flex items-center h-8">
                                <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>
                                    <div className="flex gap-x-1 px-2">
                                        <Tippy content={translate('text.bulk_download')}>
                                            <button
                                                onClick={() => {
                                                    downloadDocumentBulk(Object.values(selectedRows).map((it) => it.PayrollBatchEntryID), data[0]?.Name)
                                                    setSelectedRows({});
                                                }}
                                                className="flex p-1 rounded-btn hover:bg-primary-shade"
                                            >
                                                <ArrowDownTrayIcon
                                                    className="w-5 h-5 text-primary-contrast"/>
                                            </button>
                                        </Tippy>
                                    </div>

                                </div>
                            </div>
                        </TableBulkActions>
                    </div>
                </div>
            )}

            <ModalDefault
                show={isPayrollViewDialogOpen}
                widthClass={'max-w-7xl'}
                limitHeight={true}
                title={translate('modal_heading.upload_confirm')}
                closeButtonLabel={translate('Close')}
                onClose={handleToggleViewModal}
                buttonLabel={translate('btn.download')}
                onButtonClick={downloadDocument}
            >
                <FileViewer
                    fileType={'pdf'}
                    filePath={Env.getApiUrl('api/' + Resources.PayrollBatchesItemsDocument, Object.assign({}, {
                        PayrollBatchEntryID: selectedItem?.PayrollBatchEntryID,
                        token: getJWT().access_token,
                        type: 1
                    }))}
                    onError={(e) => {
                        console.log(e)
                    }}/>
            </ModalDefault>

            <ModalSaveResource
                title={translate('text.update_payroll_entry')}
                widthClass="max-w-md"
                gridColsClass="grid-cols-6"
                show={isPayrollEditDialogOpen}
                onClose={() => handleToggleEditModal()}
                fields={includeFields(
                    getFields(selectedItem),
                    ['IncludeIn1099']
                )}
                onSubmit={(params) => {
                    if (params) {
                        params.PayrollBatchEntryID = selectedItem?.PayrollBatchEntryID
                        dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            query: query,
                            params: params,
                            resource: Resources.TrucksPayroll,
                            piggyResource: Resources.TrucksPayroll,
                            errorMessage: true,
                            successMessage: 'Entry updated.',
                        }))
                        handleToggleEditModal();
                    }
                }}
                translate={translate}
                metadata={metadata}
            />
        </>
    )
}